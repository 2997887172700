import {
  JobList,
  ActiveJobList,
  ActiveJobListItem,
  IActiveJobListItemProps as IActiveJobListItemPropsInterface,
  IActiveJobListProps as IActiveJobListPropsInterface,
  PrinterTypes,
  PrinterList,
} from './List'

export type IActiveJobListItemProps = IActiveJobListItemPropsInterface
export type IActiveJobListProps = IActiveJobListPropsInterface

export { JobList, ActiveJobList, ActiveJobListItem, PrinterTypes, PrinterList }
