// import { css } from 'styled-components'

// export const color = css`
//   /* color styles */
// `

// export const typography = css`
//   /* typography styles */
// `

// export const spacing = css`
//   /* spacing styles */
// `

export enum Size {
  none = 'none',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  xl = 'xl',
  xxl = 'xxl',
}

export enum Spacing {
  none = 'none',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  xl = 'xl',
  xxl = 'xxl',
}

export const SpacingValues = {
  none: '0',
  xs: 'var(--margin-xs)',
  sm: 'var(--margin-sm)',
  md: 'var(--margin-md)',
  xl: 'var(--margin-xl)',
  xxl: 'var(--margin-xxl)',
}

export enum AlignSelf {
  none = 'none',
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  centerLeft = 'centerLeft',
  centerCenter = 'centerCenter',
  centerRight = 'centerRight',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight',
}

export const AlignSelfValues = {
  none: '0',
  topLeft: '0 auto auto 0',
  topCenter: '0 auto',
  topRight: '0 0 auto auto',
  centerLeft: 'auto auto auto 0',
  centerCenter: 'auto',
  centerRight: 'auto 0 auto auto',
  bottomLeft: 'auto auto 0 0',
  bottomCenter: 'auto auto 0 auto',
  bottomRight: 'auto 0 0 auto',
}

export enum AlignHorizontal {
  left = 'left',
  center = 'center',
  right = 'right',
  spaceBetween = 'spaceBetween',
  spaceAround = 'spaceAround',
  spaceEvenly = 'spaceEvenly',
}

export enum AlignVertical {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
  spaceBetween = 'spaceBetween',
  spaceAround = 'spaceAround',
  spaceEvenly = 'spaceEvenly',
}

export const AlignValues = {
  top: 'flex-start',
  left: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  right: 'flex-end',
  spaceBetween: 'space-between',
  spaceAround: 'space-around',
  spaceEvenly: 'space-evenly',
}

export enum PaddingSize {
  none = '0',
  xs = 'var(--padding-xs)',
  sm = 'var(--padding-sm)',
  md = 'var(--padding-md)',
  xl = 'var(--padding-xl)',
  xxl = 'var(--padding-xxl)',
}

export enum MarginSize {
  none = '0',
  xs = 'var(--margin-xs)',
  sm = 'var(--margin-sm)',
  md = 'var(--margin-md)',
  xl = 'var(--margin-xl)',
  xxl = 'var(--margin-xxl)',
}

export enum IconSize {
  xs = 'var(--icon-xs)',
  sm = 'var(--icon-sm)',
  md = 'var(--icon-md)',
  xl = 'var(--icon-xl)',
}
