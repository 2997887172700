import React, { ReactNode, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import { Label } from '../Label'

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  disabled?: boolean
  type?: string
  value?: string
  icon?: ReactNode
}

const Input = styled(
  ({
    label,
    disabled,
    type,
    value,
    className,
    onChange,
    icon,
    ...rest
  }: IInputProps) => (
    <div
      className={`${className} input-elem${(disabled && ' disabled') || ''}${
        value !== '' && value !== undefined ? ' filled' : ''
      }`}
    >
      {label ? <Label>{label}</Label> : null}
      <div className={`input-area${icon ? ' with-icon' : ''}`}>
        {icon && <span className="input-icon">{icon}</span>}
        <input
          type={type || 'text'}
          value={value}
          onChange={onChange}
          {...rest}
        />
        <span className="input-line" />
      </div>
    </div>
  )
)`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  width: 100%;
  .input-area {
    display: flex;
    flex-direction: row;
    height: 40px;
    overflow: hidden;
    width: 100%;
    position: relative;
    border-radius: 4px;
    background: var(--color-bg-canvas);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-secondary);
    align-items: center;
    box-sizing: border-box;
  }
  &:hover .input-area {
    border-color: var(--color-border-primary);
  }
  .input-icon {
    margin-left: 12px;
    margin-right: 2px;
    display: flex;
    position: absolute;
  }
  .input-icon svg path {
    fill: var(--color-brand-text-primary);
  }
  .input-area input {
    flex: 1;
    box-sizing: border-box;
    padding: 10px;
    background: none;
    border: none;
    border-radius: inherit;
    opacity: 0.7;
    color: var(--color-input-text);
    transition: opacity 0.3s ease;
  }
  .input-area.with-icon input {
    padding-left: 45px;
  }
  .input-area .input-line {
    transition: all 0.3s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: var(--color-brand-text-primary);
    pointer-events: none;
    transform: scaleX(0);
    opacity: 0;
    transform-origin: left;
  }
  &.filled .input-area input {
    opacity: 0.7;
  }
  .input-area input:focus {
    opacity: 1;
  }
  .input-area input:focus + .input-line,
  &.filled .input-line {
    opacity: 1;
    transform: scaleX(1);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`

export { Input }
