import React, { Component } from 'react'
import styled from 'styled-components'

import { GlobalStyle } from '../../shared/global'

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`

class Backpack extends Component {
  render() {
    const { children } = this.props
    return (
      <MainContainer>
        <GlobalStyle />
        {children}
      </MainContainer>
    )
  }
}

export { Backpack }
