import React from 'react'
import styled from 'styled-components'

const FileUploadContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  transition: all 0.3s ease;
  background: hsla(0, 0%, 0%, 0.1);
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-border-primary);
  box-sizing: border-box;
  opacity: 0.7;
  .file-upload__label {
    flex: 1;
    padding: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: var(--large);
    background: var(--color-bg-primary-dark);
    border-radius: inherit;
  }
  .file-upload__label.has-file {
    opacity: 1;
    color: var(--color-brand-text-primary);
  }
  .file-upload__button {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    transition: all 0.3s ease;
    background: var(--)
    opacity: 1;
    border-radius: 0 4px 4px 0;
    color: var(--color-text-primary);
  }
  &:hover .file-upload__button {
    color: var(--color-brand-fill-primary);
  }
  &:hover {
    opacity: 1;
    border-color: var(--color-brand-fill-primary);
  }
  .file-upload__button:hover {
    opacity: 1;
  }
  .file-upload__button:focus {
    outline: unset;
  }
  @media (max-width: 600px) {
    font-size: var(--medium);
    min-height: 40px;
    .file-upload__button {
      padding: 0 10px;
    }
  }
`

const HiddenFileInput = styled.input`
  display: none;
`

interface FileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  extensions?: string[]
  name?: string
  onFileSelected: (files: FileList | null) => void
  files?: FileList | null
}

interface FileUploadState {
  files: FileList | null
}

class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props)
    this.state = {
      files: null,
    }
  }
  private inputFile = React.createRef<HTMLInputElement>()

  triggerFileUpload = () => {
    const node = this.inputFile.current
    if (node) {
      const clickEvent = new MouseEvent('click', {
        bubbles: false,
        cancelable: true,
      })
      node.dispatchEvent(clickEvent)
    }
  }
  handleFileSelected = (files: FileList | null) => {
    this.setState({ files }, () => {
      this.props.onFileSelected(this.state.files)
    })
  }
  getAllowedExtensions = () => {
    const { extensions } = this.props
    if (!extensions || (extensions && extensions!.length === 0))
      return undefined
    const allowedExtensions = extensions!.reduce(
      (acc: string, curr: string, index) => {
        acc += `${index !== 0 ? ',' : ''}.${curr}`
        return acc
      },
      ''
    )
    return allowedExtensions
  }
  render() {
    const files = this.props.files || this.state.files
    const { label, name, className } = this.props
    const hasNoFiles = files === null || files.length === 0
    return (
      <FileUploadContainer
        className={`${className} file-upload`}
        onClick={this.triggerFileUpload}
      >
        <HiddenFileInput
          ref={this.inputFile}
          className="file-upload__file"
          name={name || 'file-upload'}
          onChange={(e) => this.handleFileSelected(e.target.files)}
          type="file"
          tabIndex={-1}
          accept={this.getAllowedExtensions()}
        />
        <div className={`file-upload__label ${!hasNoFiles ? ' has-file' : ''}`}>
          {files && files.length > 0 ? files[0].name : label || ''}
        </div>
        <button className="file-upload__button" type="button">
          SELECT A FILE
        </button>
      </FileUploadContainer>
    )
  }
}

export { FileUpload }
