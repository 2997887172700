import React from 'react'
import styled from 'styled-components'

import { Toast, ToastType } from './Toast'
import { isObjectEqual } from '../../utils/object'

const ToastsContainer = styled.div`
  position: absolute;
  right: 0;
  top: calc(var(--header-height) + 20px);
  z-index: 100;
  width: 450px;
  max-width: calc(100% - 40px);
  overflow-x: hidden;
  padding-right: 20px;
  pointer-events: none;
  > * {
    pointer-events: auto;
    margin-bottom: 20px;
  }
`

interface ToastItemProps {
  id: string
  type: ToastType
  title?: string
  description?: string
  hidden?: boolean
  autoDismiss?: boolean
}

interface ToastManagerProps {
  toasts?: ToastItemProps[]
  onDismissToastAction: (id: string) => void
}

interface ToastManagerStates {
  cachedToasts?: ToastItemProps[]
}

class ToastManager extends React.Component<
  ToastManagerProps,
  ToastManagerStates
> {
  constructor(props: ToastManagerProps) {
    super(props)
    this.state = {
      cachedToasts: props.toasts ? [...props.toasts] : [],
    }
  }

  componentDidUpdate = (prevProps: ToastManagerProps) => {
    const { toasts } = this.props
    const { cachedToasts } = this.state
    if (!isObjectEqual(prevProps.toasts, toasts)) {
      this.setState({
        cachedToasts:
          toasts &&
          toasts.map(
            ({ hidden, ...rest }) =>
              (hidden ? { hidden, ...rest } : { ...rest }) || undefined
          ),
      })
      const newCachedToasts =
        cachedToasts &&
        cachedToasts.reduce((acc, curr) => {
          const isRemoved = !(toasts && toasts.find(({ id }) => curr.id === id))
          acc = [...acc, { ...curr, hidden: isRemoved }]
          return acc
        }, [] as ToastItemProps[])
      this.setState({ cachedToasts: newCachedToasts }, () =>
        setTimeout(() => this.setState({ cachedToasts: toasts }), 300)
      )
    }
  }

  dismissToast = (id: string) => {
    this.props.onDismissToastAction(id)
  }

  render() {
    const { cachedToasts } = this.state
    return (
      <ToastsContainer>
        {cachedToasts &&
          cachedToasts.map(({ id, hidden, ...rest }) => (
            <Toast
              isMounted={!hidden}
              key={id}
              onToastClose={() => this.dismissToast(id)}
              {...rest}
            />
          ))}
      </ToastsContainer>
    )
  }
}

export { ToastManager }
