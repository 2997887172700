import { isArrayEqual } from './array'

export const isObject = (obj: { [x: string]: any }) => typeof obj === 'object'

export const isEmpty = (obj: object) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const isObjectEqual = (obj1: any, obj2: any) => {
  if (!isObject(obj1) || !isObject(obj2)) {
    return false
  }

  if (obj1 === obj2) {
    return true
  }

  const item1Keys = Object.keys(obj1).sort()
  const item2Keys = Object.keys(obj2).sort()

  if (!isArrayEqual(item1Keys, item2Keys)) {
    return false
  }
  return item2Keys.every((key) => {
    const value = obj1[key]
    const nextValue = obj2[key]

    if (value === nextValue) {
      return true
    }
    return (
      Array.isArray(value) &&
      Array.isArray(nextValue) &&
      isArrayEqual(value, nextValue)
    )
  })
}
