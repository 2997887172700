export function convertMS(miliseconds: number) {
  var month, day, hour, minute, seconds
  seconds = Math.floor(miliseconds / 1000)
  minute = Math.floor(seconds / 60)
  seconds = seconds % 60
  hour = Math.floor(minute / 60)
  minute = minute % 60
  day = Math.floor(hour / 24)
  month = Math.floor(day / 30)
  hour = hour % 24
  return {
    month: isNaN(month) ? 0 : month,
    day: isNaN(day) ? 0 : day,
    hour: isNaN(hour) ? 0 : hour,
    minute: isNaN(minute) ? 0 : minute,
    seconds: isNaN(seconds) ? 0 : seconds,
  }
}

export function formatAMPM(date: Date) {
  let hours = date.getHours()
  let minutes: number | string = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

interface TimeProps {
  month?: boolean
  day?: boolean
  hour?: boolean
  minute?: boolean
  seconds?: boolean
}

export function convertMStoText(
  miliseconds: number,
  time: TimeProps = {
    month: false,
    day: false,
    hour: true,
    minute: true,
    seconds: true,
  }
) {
  const durationMS = miliseconds ? convertMS(miliseconds) : undefined
  const timeText = durationMS
    ? `${
        (time.month &&
          durationMS.month &&
          `${durationMS.month} ${
            durationMS.month > 1 ? 'months' : 'month'
          } `) ||
        ''
      }${
        // show days when month data is not available
        (time.day &&
          !durationMS.month &&
          durationMS.day &&
          `${durationMS.day} ${durationMS.day > 1 ? 'days' : 'day'} `) ||
        ''
      }${
        (time.hour &&
          durationMS.hour &&
          `${durationMS.hour} ${durationMS.hour > 1 ? 'hrs' : 'hr'} `) ||
        ''
      }${
        (time.minute &&
          durationMS.minute &&
          `${durationMS.minute} ${durationMS.minute > 1 ? 'mins' : 'min'}`) ||
        ''
      }${
        (time.seconds &&
          durationMS.seconds &&
          durationMS.minute < 1 &&
          `${durationMS.seconds} ${
            durationMS.seconds > 1 ? 'seconds' : 'second'
          }`) ||
        ''
      }`
    : undefined
  return timeText
}
