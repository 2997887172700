import React from 'react'
import styled from 'styled-components'

import { IconSize, Size } from '../../shared/styles'

interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  className?: string
  fill?: string
  stroke?: string
  width?: string
  height?: string
  size?: Size
}

export const ArchiveIcon = styled(({ fill, stroke, className }: IconProps) => (
  <svg
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    className={`icon archive ${className}`}
  >
    <path d="M772 1012L511 761l-260 251a49 49 0 0 1-52 10c-18-7-29-24-29-43V132c0-25 21-46 47-46h588c26 0 47 21 47 46v847c0 19-11 36-29 43a49 49 0 0 1-51-10zM545 664l213 205V181H265v688l213-205c9-9 21-14 33-14s24 5 34 14z" />
  </svg>
))`
${(props) => (props.fill ? `fill: ${props.fill};` : '')}
${(props) => (props.stroke ? `stroke: ${props.stroke};` : '')}
  width: ${(props) => (props.size ? IconSize[props.size] : IconSize.xs)};
`

export const LogoIcon = styled(
  ({ fill, stroke, className, ...rest }: IconProps) => (
    <svg
      className={`icon logo ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 229.709 85"
      {...rest}
    >
      <path
        className="logo-logo"
        d="M64.826 111.831v-8.2L54.6 97.46v20.515L78.5 132.4v-12.315zm30.7 0v.08l-13.669 8.121v12.288l23.9-14.238V89.366L95.531 95.7zM80.217 71.976l-11.973 7.132v6.144l5.088 3.1 6.86-4.06 13.772 8.281 10.072-6.224zm.026-12.261l13.335 8.041 10.047-6.224L80.269 47.4 56.5 61.558l10.1 6.251z"
        transform="translate(-54.6 -47.4)"
      />
      <path
        className="logo-logo"
        d="M54.6 138.681l23.9 13.875v-11.845L64.826 132.8v-15.831L54.6 110.88zm40.931-15.648l10.226 5.935V110.7l-10.226 6.09zM92.114 146.6v-5.884l-5.139-2.981-5.113 2.955v11.81z"
        transform="translate(-54.6 -93.115)"
      />
      <g className="logo-text" transform="translate(65.572 25.267)">
        <path
          fillRule="evenodd"
          d="M331.872 145.258l-.334 3.674-4.676.565-.283-2.7c-.18-1.542-2.7-2.39-5.139-2.39-2.569 0-5.267 1.079-5.267 4.008 0 3.032 2.8 4.111 6.347 5.447l.283.1c4.676 1.773 9.944 3.726 9.944 10.175 0 5.935-4.7 9.969-11.665 9.969-5.6 0-11.228-2.569-11.28-7.426l.334-3.263 4.625-.565.283 2.8c.051 1.9 2.621 3.315 6.064 3.315 2.9 0 6.013-1.156 6.013-4.471 0-3.263-3.212-4.471-6.86-5.935l-.745-.231c-4.008-1.542-8.993-3.417-8.993-9.713 0-5.6 4.291-9.224 10.972-9.224 4.955.033 10.325 1.857 10.377 5.865zm20.607 4.651l-.283 4.214-3.777.565h-2.056v14.158l3.828.565.231 4.111h-13.668l.283-4.111 3.777-.565v-14.158h-.874l-3.777-.565-.283-4.214h4.933v-3.392l-3.777-.565-.283-4.188h9.61v8.145zm20.2-.077l-1.439 4.522a5.959 5.959 0 00-2.133-.385c-2.569 0-4.24 1.156-4.291 4.291v10.586l3.828.565.283 4.111H355.2l.283-4.111 3.777-.565v-14.158l-3.777-.565-.283-4.188h6.706l.8 1.259a10.67 10.67 0 016.4-1.876 12.114 12.114 0 013.572.514zm29.857 19.5l.283 4.188h-6.706l-.8-1.31a10.924 10.924 0 01-6.475 1.9c-6.064 0-9.944-3.546-9.944-9.456v-10.069l-3.777-.617-.283-4.06h9.61v15.16c0 3.16 1.722 4.342 4.394 4.342 2.647 0 4.394-1.156 4.394-4.342v-10.483l-3.828-.617-.283-4.06h9.635v18.834zm39.158-19.425l-.283 4.214-3.777.565H435.6v14.158l3.8.565.231 4.111h-13.64l.257-4.111 3.8-.565v-14.156h-.9l-3.777-.565-.283-4.214h4.959v-3.392l-3.8-.565-.257-4.188h9.61v8.145zm-18.526 5.524l-.283 2.647-4.676.565c0-3.212-1.156-4.676-4.111-4.676-2.647 0-4.342 1.156-4.342 4.394v6.706c0 3.16 1.439 4.342 4.933 4.342a7.494 7.494 0 004.933-2l3.212 3.16a10.648 10.648 0 01-8.171 3.546c-6.706 0-10.483-3.546-10.483-9.456v-5.884c0-5.935 3.88-9.456 9.892-9.456 5.598-.001 9.092 3.211 9.092 6.114zm26.337 2.929v6.706c0 3.16 1.67 4.342 4.342 4.342 2.7 0 4.394-1.156 4.394-4.342v-6.706c0-3.212-1.67-4.394-4.394-4.394-2.647.003-4.347 1.185-4.347 4.396zm14.286.411v5.884c0 5.935-3.88 9.456-9.944 9.456-6.013 0-9.892-3.546-9.892-9.456v-5.884c0-5.935 3.88-9.456 9.892-9.456 6.06.003 9.94 3.549 9.94 9.459z"
          transform="translate(-309.8 -139.4)"
        />
        <path
          d="M921.931 148.9a3.931 3.931 0 103.931 3.931 3.931 3.931 0 00-3.931-3.931zm0 7.04a3.109 3.109 0 113.109-3.109 3.1 3.1 0 01-3.109 3.109z"
          transform="translate(-761.726 -146.459)"
        />
        <path
          d="M930.692 159.56c-.154-.488-.334-1.439-1.1-1.747.617-.18.951-.411.951-1.182s-.725-1.131-1.543-1.131h-1.31a.429.429 0 00-.385.463v3.623c0 .128.077.18.514.18s.462-.077.462-.206v-1.387a1.151 1.151 0 01.8.154 3.732 3.732 0 01.488 1.208c.051.206.257.206.591.206.557.05.557-.078.532-.181zm-2.441-2.133V156.3c.54 0 1.182-.077 1.182.54s-.433.587-1.182.587z"
          transform="translate(-768.637 -151.363)"
        />
      </g>
    </svg>
  )
)`
  width: 230px;
  .logo-logo {
    fill: var(--color-brand-text-primary);
  }
  .logo-text {
    fill: var(--color-text-primary);
  }
`

export const BurgerMenuIcon = styled(({ active, className, ...rest }) => (
  <svg
    id="burger-menu"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 23"
    className={`${className}${(active && ' active') || ''}`}
    {...rest}
  >
    <rect className="touch-area" />
    <g className="lines">
      <rect
        className="line-3"
        transform={
          !active ? 'translate(3 17)' : 'translate(0 10) rotate(225 13 1.5)'
        }
      />
      <rect
        className="line-2"
        transform={
          !active ? 'translate(3 10)' : 'translate(0 10) rotate(360 13 1.5)'
        }
      />
      <rect
        className="line-1"
        transform={
          !active ? 'translate(3 3)' : 'translate(0 10) rotate(315 13 1.5)'
        }
      />
    </g>
  </svg>
))`
  width: 26px;
  height: 23px;
  .lines {
    transition: opacity 0.3s ease;
    opacity: 0.6;
  }
  .lines > * {
    fill: var(--color-text-primary);
    width: 20px;
    height: 3px;
    transition: all 0.3s ease;
  }
  .touch-area {
    width: 26px;
    height: 23px;
    opacity: 0;
  }
  &.active .lines > * {
    width: 26px;
  }
  &.active .line-2 {
    opacity: 0;
  }
  &:active .lines {
    opacity: 1;
  }
  @media (min-width: 481px) {
    &:hover .lines {
      opacity: 1;
    }
  }
`

export const ProfileIcon = styled(({ active, className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    className={`${className}${(active && ' active') || ''}`}
    {...rest}
  >
    <path
      d="M18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm0,4.5A4.5,4.5,0,1,1,13.5,12,4.494,4.494,0,0,1,18,7.5Zm0,21.3a10.8,10.8,0,0,1-9-4.83c.045-2.985,6-4.62,9-4.62s8.955,1.635,9,4.62a10.8,10.8,0,0,1-9,4.83Z"
      transform="translate(-3 -3)"
    />
  </svg>
))`
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
  path {
    fill: var(--color-text-primary);
  }
`

export const PlusIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.029 19.029"
    className={className}
  >
    <g transform="translate(1 1)">
      <path
        d="M0,0V17.029"
        transform="translate(17.029 8.514) rotate(90)"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M0,0V17.029"
        transform="translate(8.514)"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
))`
  width: 20px;
  height: 20px;
`

export const OverviewIcon = styled(({ className }: IconProps) => (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className={className}
  >
    <rect width="25" height="25" opacity="0" />
    <rect width="10" height="12" rx="1" transform="translate(1 1)" />
    <rect
      width="10"
      height="7.083"
      rx="1"
      transform="translate(14.167 0.833)"
      opacity="0.4"
    />
    <rect
      width="10"
      height="7"
      rx="1"
      transform="translate(1 17)"
      opacity="0.2"
    />
    <rect
      width="10"
      height="12.917"
      rx="1"
      transform="translate(14.167 11.25)"
      opacity="0.5"
    />
  </svg>
))``

export const JobsIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.567"
    height="19.042"
    viewBox="0 0 22.567 19.042"
    className={className}
  >
    <path
      d="M6.153,2.4a.529.529,0,0,0-.749,0L2.6,5.194l-1-.975a.529.529,0,0,0-.749,0l-.692.693a.529.529,0,0,0,0,.749l2.1,2.089a.563.563,0,0,0,.776,0l.687-.688L6.9,3.88a.533.533,0,0,0,0-.749Zm0,7.016a.529.529,0,0,0-.749,0L2.6,12.228l-1-.974a.529.529,0,0,0-.749,0l-.692.692a.529.529,0,0,0,0,.749l2.092,2.094a.563.563,0,0,0,.776,0l.692-.692L6.9,10.914a.529.529,0,0,0,0-.745ZM2.821,17.059a2.116,2.116,0,1,0,0,4.231,2.116,2.116,0,1,0,0-4.231Zm19.041.705H9.168a.705.705,0,0,0-.705.705v1.41a.705.705,0,0,0,.705.705H21.862a.705.705,0,0,0,.705-.705v-1.41A.705.705,0,0,0,21.862,17.764Zm0-14.1H9.168a.705.705,0,0,0-.705.705v1.41a.705.705,0,0,0,.705.705H21.862a.705.705,0,0,0,.705-.705V4.365A.705.705,0,0,0,21.862,3.66Zm0,7.052H9.168a.705.705,0,0,0-.705.705v1.41a.705.705,0,0,0,.705.705H21.862a.705.705,0,0,0,.705-.705v-1.41A.705.705,0,0,0,21.862,10.712Z"
      transform="translate(0 -2.248)"
    />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const PrintersIcon = styled(({ className }) => (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M6,0V6H18V0ZM.27,9A.252.252,0,0,0,0,9.27V17.7a.269.269,0,0,0,.27.27H3v-6H21v6h2.73A.269.269,0,0,0,24,17.7V9.27A.252.252,0,0,0,23.73,9H.3ZM6,15v9H18V15Z"
      transform="translate(0)"
    />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const StatisticsIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.675"
    height="23.223"
    viewBox="0 0 24.675 23.223"
    className={className}
  >
    <path
      d="M23.94,13.063H13.177l7.168,7.168a.728.728,0,0,0,1.007.031,10.863,10.863,0,0,0,3.317-6.389.727.727,0,0,0-.728-.81Zm-.718-2.939A10.912,10.912,0,0,0,13.1,0a.73.73,0,0,0-.762.735V10.886h10.15a.73.73,0,0,0,.734-.762ZM10.16,13.063V2.3a.727.727,0,0,0-.809-.728A10.878,10.878,0,0,0,.006,12.717,11.02,11.02,0,0,0,11.023,23.223a10.8,10.8,0,0,0,6.135-2,.721.721,0,0,0,.071-1.092Z"
      transform="translate(0 0)"
    />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const ReportsIcon = styled(({ className }) => (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="23.267"
    height="23.267"
    viewBox="0 0 23.267 23.267"
  >
    <path
      d="M7.626,5.165V23.174a.785.785,0,0,1-.783.783h0a.785.785,0,0,1-.783-.783V6.954H5.165a1.788,1.788,0,0,0-1.79,1.79V24.852a1.788,1.788,0,0,0,1.79,1.79H24.913a1.729,1.729,0,0,0,1.728-1.728V5.165a1.788,1.788,0,0,0-1.79-1.79L9.3,3.487A1.6,1.6,0,0,0,7.626,5.165Zm3.691,1.79h4.7a.785.785,0,0,1,.783.783h0a.785.785,0,0,1-.783.783h-4.7a.785.785,0,0,1-.783-.783h0A.785.785,0,0,1,11.317,6.954Zm0,8.949h8.278a.785.785,0,0,1,.783.783h0a.785.785,0,0,1-.783.783H11.317a.785.785,0,0,1-.783-.783h0A.785.785,0,0,1,11.317,15.9Zm10.962,6.04H11.317a.785.785,0,0,1-.783-.783h0a.785.785,0,0,1,.783-.783H22.279a.785.785,0,0,1,.783.783h0A.785.785,0,0,1,22.279,21.944Zm0-8.949H11.317a.785.785,0,0,1-.783-.783h0a.785.785,0,0,1,.783-.783H22.279a.785.785,0,0,1,.783.783h0A.785.785,0,0,1,22.279,12.995Z"
      transform="translate(-3.375 -3.375)"
      fill="#fff"
    />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const SettingsIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className={className}
  >
    <path
      d="M25.368,17.125a7.817,7.817,0,0,0,.064-1.25c0-.438-.064-.812-.064-1.25l2.684-2.062a.574.574,0,0,0,.128-.812L25.623,7.437a.623.623,0,0,0-.767-.25l-3.2,1.25a9.3,9.3,0,0,0-2.173-1.25l-.448-3.312a.685.685,0,0,0-.639-.5H13.286a.685.685,0,0,0-.639.5l-.511,3.312a10.825,10.825,0,0,0-2.174,1.25l-3.2-1.25A.6.6,0,0,0,6,7.437L3.441,11.75a.755.755,0,0,0,.128.812l2.749,2.062c0,.438-.064.812-.064,1.25s.064.812.064,1.25L3.634,19.187A.574.574,0,0,0,3.506,20l2.557,4.313a.623.623,0,0,0,.767.25l3.2-1.25a9.3,9.3,0,0,0,2.173,1.25l.511,3.312a.62.62,0,0,0,.639.5h5.114a.685.685,0,0,0,.639-.5l.512-3.312a10.819,10.819,0,0,0,2.173-1.25l3.2,1.25a.6.6,0,0,0,.767-.25L28.308,20a.754.754,0,0,0-.128-.812ZM15.843,20.25a4.376,4.376,0,1,1,4.475-4.375A4.4,4.4,0,0,1,15.843,20.25Z"
      transform="translate(-3.375 -3.375)"
    />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const ResinIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className={className}
  >
    <path d="M4.182,0a1.58,1.58,0,0,0,.5,3.121H6.242v4.4c-.281.531-3.745,7.21-5.181,9.644A6.2,6.2,0,0,0,0,20.472a4.583,4.583,0,0,0,1.28,3.215,4.583,4.583,0,0,0,3.215,1.28H20.506a4.551,4.551,0,0,0,3.121-1.28h.094A4.583,4.583,0,0,0,25,20.472a6.485,6.485,0,0,0-1.061-3.308c-1.436-2.434-4.9-9.114-5.181-9.644v-4.4h1.561a1.561,1.561,0,1,0,0-3.121H4.713a1.561,1.561,0,0,0-.281,0,1.56,1.56,0,0,0-.187,0ZM9.363,3.118h6.242V8.206l.187.281s1.966,3.839,3.714,7.116H5.462C7.21,12.326,9.176,8.487,9.176,8.487l.187-.281Z" />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`
export const LikeIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    className={className}
  >
    <path d="M19.922,10.938h3.906A1.172,1.172,0,0,1,25,12.109V23.828A1.172,1.172,0,0,1,23.828,25H19.922a1.172,1.172,0,0,1-1.172-1.172V12.109A1.172,1.172,0,0,1,19.922,10.938Zm1.953,12.109A1.172,1.172,0,1,0,20.7,21.875,1.172,1.172,0,0,0,21.875,23.047ZM6.25,3.977c0,2.071,1.268,3.233,1.625,4.617H2.908A2.917,2.917,0,0,0,0,11.431a3.535,3.535,0,0,0,.949,2.4l.005.005a4.079,4.079,0,0,0,.454,3.88,3.861,3.861,0,0,0,.8,3.65,2.592,2.592,0,0,0,.3,2.179C3.506,24.98,5.976,25,8.064,25H8.2a14.017,14.017,0,0,0,5.838-1.549,7.684,7.684,0,0,1,2.571-.79.586.586,0,0,0,.575-.586V11.637a.585.585,0,0,0-.174-.416C15.079,9.31,14.248,7.286,12.662,5.7a5.913,5.913,0,0,1-1.24-2.876C11.205,1.919,10.751,0,9.766,0,8.594,0,6.25.391,6.25,3.977Z" />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const ChartIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="18.75"
    viewBox="0 0 25 18.75"
    className={className}
  >
    <path
      id="Icon_awesome-chart-line"
      data-name="Icon awesome-chart-line"
      d="M24.219,20.125H3.125V5.281A.781.781,0,0,0,2.344,4.5H.781A.781.781,0,0,0,0,5.281V21.688A1.563,1.563,0,0,0,1.563,23.25H24.219A.781.781,0,0,0,25,22.469V20.906A.781.781,0,0,0,24.219,20.125ZM22.656,6.063H16.892a1.172,1.172,0,0,0-.829,2l1.582,1.582-3.583,3.583L10.48,9.646a1.562,1.562,0,0,0-2.209,0L4.917,13a.781.781,0,0,0,0,1.1l1.1,1.1a.781.781,0,0,0,1.1,0l2.249-2.25,3.583,3.583a1.562,1.562,0,0,0,2.209,0l4.688-4.687,1.582,1.582a1.172,1.172,0,0,0,2-.829V6.844A.78.78,0,0,0,22.656,6.063Z"
      transform="translate(0 -4.5)"
    />
  </svg>
))`
  path {
    fill: var(--color-text-primary);
  }
`

export const DentaformIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 84.954"
    className={className}
  >
    <defs>
      <clipPath id="clip" />
    </defs>
    <g id="dentaform" transform="translate(-640.04 -190.864)">
      <path
        id="Subtraction_1"
        d="M44.69,84.954H3.286a12.291,12.291,0,0,1-1.754-.237A9.06,9.06,0,0,1,0,84.206L0,4.467A7.579,7.579,0,0,1,1.627,1.6,7.455,7.455,0,0,1,4.457,0H43.545a7.845,7.845,0,0,1,3.107,1.639A3.986,3.986,0,0,1,48,4.369l0,79.836c-.007,0-1.226.389-1.534.51A13.106,13.106,0,0,1,44.69,84.954Zm-8.133-8.077a1.358,1.358,0,1,0,1.358,1.358A1.36,1.36,0,0,0,36.556,76.876Zm4.148-.148a1.358,1.358,0,1,0,1.357,1.358A1.36,1.36,0,0,0,40.7,76.729ZM16.329,56.285H31.811l.01.01h9.95l.765-.42v-.44l-.765-.483H32.438l-2.1-2.1H17.907l-2.076,2.076H6.166l-.473.428v.467l.473.475H16.319l.01-.01h0ZM6.166,44.932h0l-.473.473v.468l.473.427h9.665l2.076,2.077H30.335l2.1-2.105H41.77l.765-.482v-.44l-.765-.419H31.82l-15.5,0-10.156,0ZM17.392,7.984a9.01,9.01,0,0,0-9,9V30.4a9.01,9.01,0,0,0,9,9h13.42a9.01,9.01,0,0,0,9-9V16.984a9.01,9.01,0,0,0-9-9Z"
        transform="translate(640.04 190.864)"
      />
      <g id="overlays" transform="translate(645.732 198.848)">
        <path
          id="Path_25"
          d="M673,6660.679h15.525l-2.082,2.088H675.058Z"
          transform="translate(-662.385 -6623.728)"
        />
        <path
          id="Path_26"
          d="M673,6662.767h15.525l-2.082-2.088H675.058Z"
          transform="translate(-662.385 -6614.466)"
        />
        <g id="usb" transform="translate(30.073 69.905)">
          <g id="Rectangle_29" strokeWidth="0.25">
            <rect width="1.565" height="0.741" stroke="none" />
            <rect
              x="0.125"
              y="0.125"
              width="1.315"
              height="0.491"
              fill="none"
            />
          </g>
          <g
            id="Rectangle_30"
            transform="translate(0.247 0.198)"
            strokeWidth="0.25"
          >
            <rect id="fill" width="1.071" height="0.247" stroke="none" />
            <path
              d="M0,0.12189728021621704h1.0707123279571533M0.9457123279571533,0v0.24689728021621704M1.0707123279571533,0.125h-1.0707123279571533M0.125,0.24689728021621704v-0.24689728021621704"
              fill="none"
              clipPath="url(#clip)"
            />
          </g>
        </g>
        <g
          id="power-button"
          transform="translate(34.024 69.115)"
          strokeWidth="0.5"
        >
          <circle cx="0.988" cy="0.988" r="0.988" stroke="none" />
          <circle cx="0.988" cy="0.988" r="0.738" fill="none" />
        </g>
      </g>
    </g>
  </svg>
))`
  width: 48px;
  height: 84.954px;
  #Subtraction_1 {
    fill: #fff;
  }
  #Path_25 {
    fill: #fefefe;
    opacity: 0.3;
  }
  #Path_26 {
    fill: #fff;
    opacity: 0.3;
  }
  #Rectangle_29 {
    fill: none;
    stroke: #707070;
  }
  #power-button {
    stroke: #fff;
    fill: none;
  }
`

export const VeloxIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.897 80.377"
    className={className}
  >
    <g id="velox" transform="translate(-938.836 -237)" opacity="0.999">
      <path
        id="outline-shape"
        d="M29.805-50,40.4-45.763V23.082l-10.592,5.3H5.092L-5.5,23.082V-45.763L5.092-50Z"
        transform="translate(945.336 288)"
        strokeWidth="2"
      />
      <g id="screen" transform="translate(957.135 289.899)" strokeWidth="1">
        <rect width="11.298" height="17.653" rx="2" stroke="none" />
        <rect
          x="0.5"
          y="0.5"
          width="10.298"
          height="16.653"
          rx="1.5"
          fill="none"
        />
      </g>
      <line
        id="right-line"
        y2="78.377"
        transform="translate(950.604 238.177)"
        strokeWidth="2"
      />
      <line
        id="left-line"
        y2="78.377"
        transform="translate(975.317 238.177)"
        strokeWidth="2"
      />
      <g
        id="power-button"
        transform="translate(961.019 310.375)"
        strokeWidth="2"
      >
        <circle cx="1.765" cy="1.765" r="1.765" stroke="none" />
        <circle cx="1.765" cy="1.765" r="0.765" fill="none" />
      </g>
      <path
        id="top-door"
        d="M0,0H18.712a0,0,0,0,1,0,0V7.592a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z"
        transform="translate(953.605 238.353)"
      />
      <rect
        id="door"
        width="18.712"
        height="31.422"
        rx="3"
        transform="translate(953.605 252.475)"
      />
      <path
        id="top-left-stroke-2"
        d="M934.746,4468.378v3.531l10.888-4.377V4464Z"
        transform="translate(5 -4212.938)"
      />
      <path
        id="top-left-stroke-1"
        d="M934.746,4468.378v3.531l10.888-4.377V4464Z"
        transform="translate(5 -4220.352)"
      />
      <path
        id="top-right-stroke-2"
        d="M945.635,4468.378v3.531l-10.888-4.377V4464Z"
        transform="translate(40.305 -4212.938)"
      />
      <path
        id="top-right-stroke-1"
        d="M945.635,4468.378v3.531l-10.888-4.377V4464Z"
        transform="translate(40.305 -4220.352)"
      />
      <path
        id="bottom-left-stroke"
        d="M934.746,4454.592V4444l10.888,4.378v10.592Z"
        transform="translate(5 -4151.984)"
      />
      <path
        id="bottom-right-stroke"
        d="M945.635,4454.592V4444l-10.888,4.378v10.592Z"
        transform="translate(40.305 -4151.984)"
      />
    </g>
  </svg>
))`
  width: 47.897px;
  height: 80.377px;
  #outline-shape,
  #screen,
  #right-line,
  #left-line,
  #power-button {
    fill: none;
    stroke: #fff;
  }
  #top-door,
  #door,
  #top-left-stroke-1,
  #top-left-stroke-2,
  #top-right-stroke-2,
  #top-right-stroke-1,
  #bottom-left-stroke,
  #bottom-right-stroke {
    fill: #fff;
  }
`

export const CloseIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={className}
  >
    <path
      d="M27.523,9.523l-2-2-8,8-8-8-2,2,8,8-8,8,2,2,8-8,8,8,2-2-8-8Z"
      transform="translate(-7.523 -7.523)"
    />
  </svg>
))`
  width: 20px;
  height: 20px;
  path {
    fill: var(--color-text-primary);
  }
`

export const AddJobIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.571 20"
    className={className}
  >
    <path
      d="M20.143,14.714H3v2.857H20.143Zm0-5.714H3v2.857H20.143Zm5.714,11.429V14.714H23v5.714H17.286v2.857H23V29h2.857V23.286h5.714V20.429ZM3,23.286H14.429V20.429H3Z"
      transform="translate(-3 -9)"
    />
  </svg>
))`
  width: 28.571px;
  height: 20px;
  path {
    fill: var(--color-text-primary);
  }
`

export const DropdownArrowIcon = styled(({ className }) => (
  <svg className={className} viewBox="0 0 10 5">
    <path d="M9,13.5l5,5,5-5Z" transform="translate(-9 -13.5)" />
  </svg>
))`
  width: 10px;
  height: 5px;
  path {
    fill: var(--color-text-primary);
  }
`

export const CheckIcon = styled(({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 10.44"
  >
    <path
      d="M4.755,14.812l-4.55-4.55a.7.7,0,0,1,0-.99l.99-.99a.7.7,0,0,1,.99,0L5.25,11.347l6.565-6.565a.7.7,0,0,1,.99,0l.99.99a.7.7,0,0,1,0,.99l-8.05,8.05A.7.7,0,0,1,4.755,14.812Z"
      transform="translate(0 -4.577)"
    />
  </svg>
))`
  width: 14px;
  height: 10.44px;
`

export const ExclamationIcon = styled(({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 5.313 17"
  >
    <path
      d="M6.438,14.344a2.656,2.656,0,1,1-2.656-2.656A2.659,2.659,0,0,1,6.438,14.344ZM1.432.837l.452,9.031a.8.8,0,0,0,.8.757h2.2a.8.8,0,0,0,.8-.757L6.13.837A.8.8,0,0,0,5.334,0H2.228A.8.8,0,0,0,1.432.837Z"
      transform="translate(-1.125)"
    />
  </svg>
))`
  width: 5.313px;
  height: 17px;
`

export const InfoIcon = styled(({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6.375 17"
  >
    <path d="M.664,14.086h.664v-4.8H.664A.664.664,0,0,1,0,8.625V7.039a.664.664,0,0,1,.664-.664H4.383a.664.664,0,0,1,.664.664v7.047h.664a.664.664,0,0,1,.664.664v1.586A.664.664,0,0,1,5.711,17H.664A.664.664,0,0,1,0,16.336V14.75A.664.664,0,0,1,.664,14.086ZM3.188,0A2.391,2.391,0,1,0,5.578,2.391,2.391,2.391,0,0,0,3.188,0Z" />
  </svg>
))`
  width: 6.375px;
  height: 17px;
`

interface CircleIconProps extends React.HTMLAttributes<HTMLDivElement> {
  radian?: number
  countdown?: number
  hidden?: boolean
}

export const CircleCountdownIcon = styled(
  ({ hidden, className }: CircleIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}${hidden ? ' hidden' : ''}`}
      viewBox="0 0 35 35"
    >
      <g>
        <circle cx="17.5" cy="17.5" fill="none" />
        <circle className="secondary" cx="17.5" cy="17.5" fill="none" />
      </g>
    </svg>
  )
)`
  width: 35px;
  height: 35px;
  transition: all 0.3s ease;
  animation: hideCircle 0.3s ease ${({ countdown = 0 }) => countdown / 1000}s
    forwards;
  g {
    stroke: #f58220;
    stroke-width: 4.5;
    fill: none;
  }
  circle {
    transition: all 0.3s ease;
    r: ${({ radian = 15 }) => radian};
    stroke-dasharray: ${({ radian = 15 }) => 2 * 3.14 * radian};
    stroke-dashoffset: ${({ radian = 15 }) => 2 * 3.14 * radian};
    transform-origin: center;
    transform: rotate(270deg);
    animation: circleStrokeAround
      ${({ countdown = 0 }) => (countdown === 0 ? 3 : countdown / 1000)}s linear
      forwards;
  }
  circle.secondary {
    animation: unset;
    stroke-dashoffset: 0;
    opacity: 0.1;
  }
  &.hidden {
    animation: unset;
    opacity: 0;
  }
  @keyframes circleStrokeAround {
    0% {
      stroke-dashoffset: ${({ radian = 15 }) => 2 * 3.14 * radian};
      opacity: 0.1;
    }
    100% {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
  @keyframes hideCircle {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
`

interface IStatusIcon extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
}

export const StatusIcon = styled(({ color, ...rest }: IStatusIcon) => (
  <div {...rest} />
))`
  transition: all 0.3s ease;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: ${({ color }) => color || 'var(--fill-p-color-1)'};
  border-radius: 100%;
  opacity: 0.7;
`

export const SearchIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20.005"
    viewBox="0 0 20 20.005"
    className={className}
  >
    <path
      d="M24.265,23.05,18.7,17.436a7.927,7.927,0,1,0-1.2,1.219l5.526,5.578a.856.856,0,0,0,1.208.031A.862.862,0,0,0,24.265,23.05ZM12.474,18.722A6.259,6.259,0,1,1,16.9,16.889,6.221,6.221,0,0,1,12.474,18.722Z"
      transform="translate(-4.5 -4.493)"
      fill="#f58220"
    />
  </svg>
))`
  width: 20px;
  height: 20px;
`

export const FilterIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.556"
    height="16"
    viewBox="0 0 17.556 16"
  >
    <path
      d="M18.556,4.5H3l6.222,7.358v5.087L12.333,18.5V11.858Z"
      transform="translate(-2 -3.5)"
    />
  </svg>
))`
  path {
    fill: none;
    stroke: var(--color-brand-text-primary);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
`

export const ExportIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.874"
    height="15"
    viewBox="0 0 16.874 15"
  >
    <path d="M11.25,3.571a.7.7,0,0,0-.205-.5L8.177.205A.7.7,0,0,0,7.679,0H7.5V3.75h3.75Zm5.479,5.452L13.925,6.2a.47.47,0,0,0-.8.331V8.438H11.247v1.875h1.875v1.91a.47.47,0,0,0,.8.331l2.8-2.827A.5.5,0,0,0,16.729,9.023Zm-11.1.82V8.906a.47.47,0,0,1,.469-.469H11.25V4.688H7.266a.705.705,0,0,1-.7-.7V0H.7A.7.7,0,0,0,0,.7V14.3a.7.7,0,0,0,.7.7h9.844a.7.7,0,0,0,.7-.7V10.313H6.094A.47.47,0,0,1,5.625,9.844Z" />
  </svg>
))`
  path {
    fill: var(--color-brand-text-primary);
  }
`

export const DeleteIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22.857"
    viewBox="0 0 20 22.857"
    className={className}
  >
    <path
      d="M1.429,20.714a2.143,2.143,0,0,0,2.143,2.143H16.429a2.143,2.143,0,0,0,2.143-2.143v-15H1.429ZM13.571,9.286a.714.714,0,1,1,1.429,0v10a.714.714,0,1,1-1.429,0Zm-4.286,0a.714.714,0,1,1,1.429,0v10a.714.714,0,1,1-1.429,0ZM5,9.286a.714.714,0,0,1,1.429,0v10a.714.714,0,1,1-1.429,0ZM19.286,1.429H13.929l-.42-.835A1.071,1.071,0,0,0,12.549,0h-5.1a1.059,1.059,0,0,0-.955.594l-.42.835H.714A.714.714,0,0,0,0,2.143V3.571a.714.714,0,0,0,.714.714H19.286A.714.714,0,0,0,20,3.571V2.143A.714.714,0,0,0,19.286,1.429Z"
      transform="translate(0 0)"
    />
  </svg>
))`
  path {
    fill: var(--color-red-secondary);
  }
`

export const BackArrowIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.576"
    height="15"
    viewBox="0 0 8.576 15"
    className={className}
  >
    <path
      d="M13.836,13.692l5.676-5.671a1.072,1.072,0,0,0-1.518-1.514l-6.43,6.426a1.07,1.07,0,0,0-.031,1.478l6.457,6.47a1.072,1.072,0,0,0,1.518-1.514Z"
      transform="translate(-11.251 -6.194)"
    />
  </svg>
))`
  path {
    fill: var(--color-brand-text-primary);
    opacity: 0.5;
  }
`

export const SadIcon = styled(({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.609 27.609"
  >
    <g transform="translate(-2 -2)">
      <path
        id="Path_35"
        data-name="Path 35"
        d="M28.609,15.8A12.8,12.8,0,1,1,15.8,3a12.8,12.8,0,0,1,12.8,12.8Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,23.561A6.914,6.914,0,0,1,17.122,21a6.914,6.914,0,0,1,5.122,2.561"
        transform="translate(-1.317 -2.635)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M13.5,13.5h.013"
        transform="translate(-1.537 -1.537)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22.5,13.5h.013"
        transform="translate(-2.854 -1.537)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
))`
  width: 27px;
  height: 27px;
  path {
    stroke: #c33;
  }
`

export const SmileyIcon = styled(({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.609 27.609"
  >
    <g transform="translate(-2 -2)">
      <path
        d="M28.609,15.8A12.8,12.8,0,1,1,15.8,3a12.8,12.8,0,0,1,12.8,12.8Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12,21a6.914,6.914,0,0,0,5.122,2.561A6.914,6.914,0,0,0,22.244,21"
        transform="translate(-1.317 -2.635)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M13.5,13.5h.013"
        transform="translate(-1.537 -1.537)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22.5,13.5h.013"
        transform="translate(-2.854 -1.537)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
))`
  width: 27px;
  height: 27px;
  path {
    stroke: #20f562;
  }
`

export const FeedbackIcon = styled(({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.379 74.379"
    className={className}
  >
    <path
      d="M69.941,3h-59.5a7.428,7.428,0,0,0-7.4,7.438L3,77.379,17.876,62.5H69.941a7.46,7.46,0,0,0,7.438-7.438V10.438A7.46,7.46,0,0,0,69.941,3ZM43.908,47.627H36.471V40.189h7.438Zm0-14.876H36.471V17.876h7.438Z"
      transform="translate(-3 -3)"
    />
  </svg>
))`
  width: 74px;
  height: 74px;
  path {
    fill: rgba(255, 255, 255, 0.5);
  }
`

export const EditIcon = styled(({ className }) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    style={{ fill: 'none' }}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.474 5.90807L18.592 8.02507L16.474 5.90807ZM17.836 4.04307L12.109 9.77007C11.8131 10.0656 11.6113 10.4421 11.529 10.8521L11 13.5001L13.648 12.9701C14.058 12.8881 14.434 12.6871 14.73 12.3911L20.457 6.66407C20.6291 6.49197 20.7656 6.28766 20.8588 6.06281C20.9519 5.83795 20.9998 5.59695 20.9998 5.35357C20.9998 5.11019 20.9519 4.86919 20.8588 4.64433C20.7656 4.41948 20.6291 4.21517 20.457 4.04307C20.2849 3.87097 20.0806 3.73446 19.8557 3.64132C19.6309 3.54818 19.3899 3.50024 19.1465 3.50024C18.9031 3.50024 18.6621 3.54818 18.4373 3.64132C18.2124 3.73446 18.0081 3.87097 17.836 4.04307V4.04307Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 15.5V18.5C19 19.0304 18.7893 19.5391 18.4142 19.9142C18.0391 20.2893 17.5304 20.5 17 20.5H6C5.46957 20.5 4.96086 20.2893 4.58579 19.9142C4.21071 19.5391 4 19.0304 4 18.5V7.5C4 6.96957 4.21071 6.46086 4.58579 6.08579C4.96086 5.71071 5.46957 5.5 6 5.5H9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))`
  width: 25px;
  height: 25px;
  stroke: #c9d1d9;
  path {
    stroke: inherit;
  }
`

export const IconsList = {
  Archive: <ArchiveIcon />,
  ProfileIcon: <ProfileIcon />,
  Plus: <PlusIcon />,
}
