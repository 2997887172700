import React, { Component } from 'react'
import styled from 'styled-components'

const BackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--color-bg-canvas);
`

class Background extends Component {
  render() {
    return <BackgroundContainer />
  }
}

export { Background }
