import React from 'react'
import styled from 'styled-components'
import lodash from 'lodash'

import { SmallButton } from '../Button'
import { LineGraph } from './LineGraph'

const GraphCardContainer = styled.div`
  padding: 20px 25px;
  background: var(--color-bg-canvas-inset);
  border: 1px solid var(--color-border-primary);
  border-radius: 5px;
  padding-bottom: 1.5rem;
  .graph-card__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
  .graph-card__button-group > *:not(:last-child) {
    margin-right: 20px;
  }
  .graph-card__title {
    flex: 1;
    font-size: var(--medium);
    opacity: 0.5;
  }
  .graph-card__button-group-mobile {
    flex-direction: row;
    justify-content: space-evenly;
    margin: 5px 0 10px;
    display: none;
  }
  .graph-card__button-group-mobile > * {
    width: 70px;
    font-size: var(--medium);
  }
  @media (max-width: 600px) {
    padding: 0;
    background: transparent;
    box-sizing: border-box;
    padding: 12px;
    .graph-card__content {
      padding: 0;
      margin-bottom: 20px;
    }
    .graph-card__button-group-mobile {
      display: flex;
    }
    .graph-card__button-group {
      display: none;
    }
  }
`

type IDataItem = {
  value: number
  xValue?: string
  label: string
}

interface IGraphProps extends React.HTMLAttributes<HTMLDivElement> {
  containerWidth?: number
  containerHeight?: number
  smoothing?: number
  min?: number
  max?: number
  stepSize?: number
  suffix?: string
  loading?: boolean
  selectedIndex?: number
  data: IDataItem[]
  dataName?: string
}

interface IGraphCardProps extends IGraphProps {
  onDaily?: () => void
  onWeekly?: () => void
  onMonthly?: () => void
  dailyActive?: boolean
  weeklyActive?: boolean
  monthlyActive?: boolean
  title?: string
}

interface IGraphCardStates {
  activeIndex: number
  containerWidth?: number
  containerHeight?: number
  smoothing?: number
  min?: number
  stepSize?: number
}

class LineGraphCard extends React.Component<IGraphCardProps, IGraphCardStates> {
  constructor(props) {
    super(props)
    const { innerWidth } = window
    const containerWidth = innerWidth > 500 ? 500 : innerWidth
    this.state = {
      activeIndex: 0,
      containerWidth,
      containerHeight: containerWidth > 500 ? 200 : 200,
      smoothing: 0.15,
      stepSize: undefined,
    }
  }
  componentWillUpdate(nextProps: IGraphCardProps) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ activeIndex: 0 })
    }
  }

  updateDimensions = () => {
    const { innerWidth } = window
    const containerWidth = innerWidth > 500 ? 500 : innerWidth
    if (containerWidth !== this.state.containerWidth) {
      this.setState({
        containerWidth,
        containerHeight: containerWidth > 500 ? 200 : 200,
      })
    }
  }

  onDaily = () => this.setState({ activeIndex: 0 }, this.props.onDaily)

  onWeekly = () => this.setState({ activeIndex: 0 }, this.props.onWeekly)

  onMonthly = () => this.setState({ activeIndex: 0 }, this.props.onMonthly)

  debounceUpdateDimensions = lodash.debounce(this.updateDimensions, 100)
  componentDidMount() {
    window.addEventListener('resize', this.debounceUpdateDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceUpdateDimensions)
  }
  onSelectIndex = (index: number) => {
    this.setState({ activeIndex: index })
  }

  render() {
    const {
      dataName,
      title = 'Number of Jobs',
      min,
      stepSize,
      suffix,
      loading,
      className,
      onDaily = () => {},
      dailyActive,
      onWeekly = () => {},
      weeklyActive,
      onMonthly = () => {},
      monthlyActive,
      data,
      max = data ? Math.max(...data.map((d) => d.value)) : undefined,
    } = this.props

    const { containerWidth, containerHeight, activeIndex } = this.state
    return (
      <GraphCardContainer className="line-graph-card">
        <div className="graph-card__content">
          <span className="graph-card__title">{title || ''}</span>
          <div className="graph-card__button-group">
            {onDaily && (
              <SmallButton
                active={dailyActive}
                onClick={this.onDaily}
                label="Daily"
              />
            )}
            {onWeekly && (
              <SmallButton
                active={weeklyActive}
                onClick={this.onWeekly}
                label="Weekly"
              />
            )}
            {onMonthly && (
              <SmallButton
                active={monthlyActive}
                onClick={this.onMonthly}
                label="Monthly"
              />
            )}
          </div>
        </div>
        <div className="graph-card__button-group-mobile">
          {onDaily && (
            <SmallButton
              onClick={this.onDaily}
              active={dailyActive}
              label="Daily"
            />
          )}
          {onWeekly && (
            <SmallButton
              onClick={this.onWeekly}
              active={weeklyActive}
              label="Weekly"
            />
          )}
          {onMonthly && (
            <SmallButton
              onClick={this.onMonthly}
              active={monthlyActive}
              label="Monthly"
            />
          )}
        </div>
        <LineGraph
          dataName={dataName}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          data={data}
          min={min}
          max={max}
          stepSize={stepSize}
          suffix={suffix}
          loading={loading}
          onSelectIndex={this.onSelectIndex}
          className={className}
          selectedIndex={data && data[activeIndex] ? activeIndex : 0}
        />
      </GraphCardContainer>
    )
  }
}

const LineGraphCardGroup = styled.div`
  overflow: visible;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(440px, 540px));
  grid-gap: var(--margin-xl);
  padding: 0;
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`

export { LineGraph, LineGraphCard, LineGraphCardGroup }
