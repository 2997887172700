import styled from 'styled-components'

// Used to do linebreaks in flexbox
export default styled.span`
  flex-basis: 100% !important;
  height: 0 !important;
  margin: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  display: block;
`
