import React from 'react'
import styled from 'styled-components'

const MarqueeTextContainer = styled.div`
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  .marquee {
    display: inline-block;
    width: fit-content;
    white-space: nowrap;
    animation: scrollLeft 10s linear infinite;
    padding-left: 101%;
    @keyframes scrollLeft {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
`

interface MarqueeTextStates {
  isOverflowing: boolean
}

interface MarqueeTextProps {
  text?: string
}

export default class MarqueeText extends React.Component<
  MarqueeTextProps,
  MarqueeTextStates
> {
  state = {
    isOverflowing: false,
  }
  textElement
  componentDidMount() {
    if (this.isTextOverflowing() !== this.state.isOverflowing) {
      this.setState({ isOverflowing: this.isTextOverflowing() })
    }
  }
  componentDidUpdate() {
    if (this.isTextOverflowing() !== this.state.isOverflowing) {
      this.setState({ isOverflowing: this.isTextOverflowing() })
    }
  }

  isTextOverflowing() {
    const { textElement } = this
    const hasOverflowingChildren =
      textElement.offsetHeight < textElement.scrollHeight ||
      textElement.offsetWidth < textElement.scrollWidth
    return hasOverflowingChildren
  }

  render() {
    const { isOverflowing } = this.state
    return (
      <MarqueeTextContainer
        ref={(el) => {
          this.textElement = el
        }}
      >
        {!isOverflowing ? (
          this.props.text
        ) : (
          <div className="marquee">{this.props.text}</div>
        )}
      </MarqueeTextContainer>
    )
  }
}
