import React from 'react'
import styled from 'styled-components'

interface ProgressBar extends React.HTMLAttributes<HTMLDivElement> {
  value?: number
  min?: number
  max?: number
  color?: string
}

const ProgressBar = styled(
  ({ color, value, min, max, className, ...rest }: ProgressBar) => (
    <div className={`${className} progress-bar`} {...rest}>
      <div className="progress-bar__filled" />
      <div className="progress-bar__bg" />
    </div>
  )
)`
  width: 100%;
  height: 20px;
  position: relative;
  > * {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    border-radius: 25px;
    transition: all 0.3s ease;
  }
  .progress-bar__filled {
    background: ${({ color = '#F58220' }) => color};
    width: ${({ value = 0, min = 0, max = 100 }) => {
      let percent = ((value - min) / (max - min)) * 100
      percent = percent < 0 ? 0 : percent > 100 ? 100 : percent
      return percent
    }}%;
    z-index: 1;
  }
  .progress-bar__bg {
    opacity: 0.1;
    background: ${({ color = '#F58220' }) => color};
  }
`

export { ProgressBar }
