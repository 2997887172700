import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { PaddingSize, Size } from '../../shared/styles'

export interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  label?: string
  secondary?: boolean
  inverted?: boolean
  ghosted?: boolean
  size?: Size
  icon?: ReactNode
  fullWidth?: boolean
  type?: string
  disabled?: boolean
  borderless?: boolean
}

export interface IButtonLinkProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  label?: string
  fontSize?: string
  disabled?: boolean
  type?: string
}

const BaseButton = styled(({ disabled, className, children, ...rest }) => (
  <button
    type="button"
    className={`${className}${(disabled && ' disabled') || ''}`}
    {...rest}
  >
    <div className="button-tab-catcher" tabIndex={-1} />
    <div className="button-content">{children}</div>
  </button>
))`
  position: relative;
  box-sizing: border-box;
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .button-tab-catcher {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: inherit;
  }
  &:focus,
  .button-tab-catcher:focus,
  .button-content:focus {
    outline: none;
  }
  &:focus > .button-tab-catcher {
    box-shadow: inset 0 0 2px 2px #51a7e8;
  }
`

const Button = styled(
  ({
    label,
    icon,
    secondary,
    inverted,
    ghosted,
    children,
    size,
    fullWidth,
    className,
    borderless,
    ...rest
  }: IButtonProps) => (
    <BaseButton
      type="button"
      className={`${className}${secondary ? ' secondary' : ' primary'}${
        inverted ? ' inverted' : ''
      }${borderless ? ' borderless' : ''}`}
      {...rest}
      title={label}
    >
      {icon ? (
        <div className="button-icon">
          {icon}
          <span className="button-icon__line" />
        </div>
      ) : null}
      {label && <span className="btn-label">{label || null}</span>}
      {children || null}
    </BaseButton>
  )
)`
  &.inverted {
    --button-primary-color: black;
    --button-secondary-color: var(--fill-p-color-1);
  }
  border-radius: 4px;
  color: var(--color-button-text);
  background: var(--color-button-bg);
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  min-height: 40px;
  transition: all 0.25s ease;
  width: ${(props) => (props.fullWidth ? '100%' : 'unset')};
  .btn-label {
    padding: ${(props) =>
        !props.size ? PaddingSize[Size.sm] : PaddingSize[props.size]}
      calc(
        2 *
          ${(props) =>
            !props.size ? PaddingSize[Size.sm] : PaddingSize[props.size]}
      );
    box-sizing: border-box;
    flex: 1;
  }
  .button-content {
    pointer-events: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    word-break: break-word;
    min-height: inherit;
    min-width: inherit;
    max-width: 200px;
  }

  .button-content .button-icon {
    min-height: inherit;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    position: relative;
  }

  .button-content .button-icon svg {
    max-width: 25px;
  }

  .button-content .button-icon .button-icon__line {
    height: calc(100% - 10px);
    width: 1px;
    background: var(--color-button-text);
    opacity: 0.5;
    position: absolute;
    right: 0;
  }
  .button-content .button-icon {
    fill: var(--color-button-text);
    stroke: var(--color-button-text);
  }

  &:not(.secondary) > .button-content {
    transition: all 0.3s ease;
    opacity: 1;
  }

  &:not(.secondary):hover {
    background: var(--color-button-bg-hovered);
  }
  &:not(.secondary):active {
    opacity: 0.8;
    transform: scale(0.98);
  }

  &.secondary {
    background: var(--color-button-secondary-bg);
    color: ${({ color }) => color || 'var(--color-button-secondary-fill)'};
    border: var(--color-button-secondary-border-width) solid
      ${({ color }) => color || 'var(--color-button-secondary-fill)'};
  }

  &.secondary .button-content {
    opacity: 1;
  }
  &.secondary .button-content .button-icon {
    fill: var(--color-button-secondary-fill);
    stroke: var(--color-button-secondary-fill);
  }
  &.secondary .button-content .button-icon__line {
    background: var(--color-button-secondary-fill);
    opacity: 1;
  }

  &.borderless {
    background: unset;
    border: unset;
  }

  &.secondary:hover {
    transition: all 0.2s ease;
    background: var(--color-button-secondary-fill);
    color: black;
  }
  &.secondary:hover .button-content .button-icon {
    fill: var(--color-button-secondary-text-hovered);
    stroke: var(--color-button-secondary-text-hovered);
  }
  &.secondary:hover .button-content .button-icon__line {
    background: var(--color-button-secondary-text-hovered);
  }
  &.secondary:active {
    transition: all 0.2s ease;
    opacity: 0.8;
    transform: scale(0.99);
  }
`

const LinkButton = styled(
  ({ label, fontSize, className, disabled, ...rest }: IButtonLinkProps) => (
    <BaseButton
      className={`${className}${(disabled && ' disabled') || ''}`}
      {...rest}
    >
      {label}
    </BaseButton>
  )
)`
  font-size: var(--medium);
  text-decoration: underline;
  color: var(--color-link-button-text);
  border: unset;
  background: unset;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
    transform: scale(0.98);
  }
`

interface ISidebarButtonProp extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean
  icon?: ReactNode
  label: string
}

const SidebarButton = styled(
  ({ active, label, icon, className, ...rest }: ISidebarButtonProp) => (
    <BaseButton
      className={`${className}${(active && ' active') || ''}`}
      {...rest}
      title={label}
    >
      <span className="sidebar-button__highlight" />
      <div className="sidebar-button__content">
        <span className="sidebar-button__icon">{icon}</span>
        <span className="sidebar-button__label">{label}</span>
      </div>
    </BaseButton>
  )
)`
  width: 100%;
  height: 50px;
  background: var(--color-sidebar-button-bg);
  text-align: left;
  color: var(--color-sidebar-button-text);
  font-size: var(--large);
  text-transform: capitalize;
  .button-content {
    height: 100%;
  }
  .sidebar-button__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    transition: all 0.3s ease;
  }
  .sidebar-button__icon {
    margin-right: 20px;
    fill: var(--color-text-primary);
  }
  .sidebar-button__icon svg {
    width: 25px;
    height: auto;
  }
  .sidebar-button__highlight {
    transition: all 0.3s ease;
    opacity: 0;
    width: 5px;
    height: 30px;
    background: var(--color-sidebar-button-highlight);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    border-radius: 0 5px 5px 0;
  }
  &:hover {
    background: var(--color-bg-canvas-inset);
  }
  &.active {
    background: var(--color-bg-canvas);
  }
  &:hover .sidebar-button__content {
    opacity: 0.8;
  }
  &.active {
    color: var(--color-sidebar-button-highlight);
  }
  &.active .sidebar-button__content {
    opacity: 1;
    transform: translateX(5px);
  }
  &.active .sidebar-button__icon svg,
  &.active .sidebar-button__icon svg path,
  &.active .sidebar-button__icon svg g {
    fill: var(--color-sidebar-button-highlight);
  }
  &.active .sidebar-button__highlight {
    opacity: 1;
    transform: translate(0, -50%);
  }
  @media (max-width: 320px) {
    height: 45px;
  }
`

export const SidebarSubButton = styled(SidebarButton)`
  background: hsl(0deg 0% 0% / 30%);
  font-size: calc(var(--large) - 2px);
  .sidebar-button__highlight {
    background: black;
  }
  &:hover {
    background: transparent;
  }
  &.active {
    background: transparent;
  }
  &.active .sidebar-button__highlight {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background: black;
  }
  &.active .sidebar-button__content {
    transition: transform 0.25s ease;
    transform: scale(0.95);
  }
`

interface ISidebarSubButtonGroup extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean
}

export const SidebarSubButtonGroup = styled(
  ({ className, active, ...rest }: ISidebarSubButtonGroup) => (
    <div className={`${className}${active ? ' active' : ''}`} {...rest}></div>
  )
)`
  max-height: 0px;
  min-height: 0px;
  border-color: hsl(0deg 0% 0% / 60%);
  border-style: solid;
  border-width: 0;
  overflow-y: hidden;
  transition: border-width 0.3s ease-out, max-height 0.3s ease-out,
    min-height 0.3s ease-out;
  overflow: hidden;
  &.active {
    transition: min-height 0.3s ease-out, max-height 0.3s ease-out;
    border-width: 1px 0px;
    max-height: ${(props: ISidebarSubButtonGroup) =>
      `${React.Children.count(props.children) * 50}px`};
    min-height: ${(props: ISidebarSubButtonGroup) =>
      `${React.Children.count(props.children) * 50}px`};
  }
`

interface ISmallButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  label?: string
  active?: boolean
  secondary?: boolean
  disabled?: boolean
}

const SmallButton = styled(
  ({
    label,
    className,
    active,
    secondary,
    disabled,
    ...rest
  }: ISmallButtonProps) => (
    <BaseButton
      className={`${className}${(active && ' active') || ''}${
        secondary ? ' secondary' : ' primary'
      }${disabled ? ' disabled' : ''}`}
      title={label}
      {...rest}
    >
      {label}
    </BaseButton>
  )
)`
  font-family: D-DIN;
  font-size: var(--medium);
  padding: 5px 10px;
  background: unset;
  color: var(--color-text-primary);
  opacity: 0.8;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-primary-hovered);
  transition: all 0.3s ease;
  &.disabled {
    pointer-events: none;
    filter: grayscale(1) brightness(0.25);
  }
  &:not(.secondary):not(.active):hover {
    opacity: 1;
    color: var(--color-brand-fill-primary);
    border-color: var(--color-brand-fill-primary);
  }
  &.secondary {
    color: var(--color-brand-fill-primary);
    border-color: var(--color-brand-fill-primary);
    opacity: 1;
  }
  &.secondary:hover {
    opacity: 1;
    color: var(--color-bg-primary);
    background: var(--color-brand-fill-primary);
  }
  &.secondary:active {
    transform: scale(0.98);
    opacity: 0.7;
  }
  &.active {
    border-color: var(--color-brand-fill-primary);
    color: var(--color-bg-primary);
    background: var(--color-brand-fill-primary);
    opacity: 1;
  }
`

const ToggleButton = styled(({ icon, label, className, active, ...rest }) => (
  <BaseButton
    className={`${className}${(active && ' active') || ''}`}
    title={label}
    {...rest}
  >
    {icon ? <span className="button-icon">{icon}</span> : null}
    {label}
  </BaseButton>
))`
  color: var(--color-text-primary);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-text-primary);
  padding: 10px var(--medium);
  border-radius: 10px;
  background: transparent;
  opacity: 0.6;
  &,
  .button-icon svg path {
    transition: all 0.3s ease;
  }
  transition: all 0.3s ease;
  .button-icon svg {
    stroke: #fff;
    stroke-width: 1.5px;
    margin-right: 8px;
  }
  .button-content {
    display: flex;
    font-family: D-DIN Exp;
  }
  &:hover {
    border-color: var(--color-brand-fill-primary);
    color: var(--color-brand-fill-primary);
    opacity: 1;
  }
  &.active {
    background: var(--color-brand-fill-primary);
    color: var(--color-bg-primary);
    border-color: var(--color-brand-fill-primary);
    opacity: 1;
  }
  ,
  &:hover .button-icon svg path {
    stroke: var(--color-brand-fill-primary);
  }
  &.active .button-icon svg path {
    stroke: var(--color-bg-primary);
  }
`

export { Button, LinkButton, SidebarButton, SmallButton, ToggleButton }
