import React from 'react'
import styled from 'styled-components'

import { Label } from '../Label'
import { DropdownArrowIcon } from '../Icon'

interface Option {
  label: string
  value: string
}

export type Options = Option[]

interface IDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  options?: Options
  value?: string
  onSelectOption: (value: string) => void
  maxItemCount?: number
  nosort?: boolean
}

interface IDropdownStates {
  filterText?: string
  collapsed: boolean
}

interface IDropdownContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  itemCount: number
  maxItemCount?: number
}

const DropdownContainer = styled(
  ({ itemCount, maxItemCount, ...rest }: IDropdownContainerProps) => (
    <div {...rest} />
  )
)`
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
  opacity: 0.7;
  margin-bottom: 14px;
  .dropdown-box {
    border-radius: 4px;
    background: hsla(0, 0%, 0%, 0.2);
    display: flex;
    flex-direction: column;
    max-height: 40px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-primary);
  }
  &:hover .dropdown-box {
    border-color: var(--color-border-primary-hovered);
  }
  .dropdown-box__options {
    position: absolute;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 40px;
    max-height: 40px;
    transition: opacity 0.3s, max-height 0.3s ease;
    width: calc(100% + 2px);
    opacity: 0;
    top: 38px;
    left: -1px;
    border-radius: 0 0 4px 4px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: var(--color-bg-secondary);
    pointer-events: none;
  }
  .dropdown-box__options > * {
    min-height: 40px;
    padding: 0 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    background: var(--color-bg-canvas-inset);
  }
  .dropdown-box__options > *:hover {
    background: var(--color-bg-primary);
  }
  .dropdown__mobile {
    width: 100%;
    height: 40px;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
  }
  .value-label {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 40px;
    background: var(--color-bg-primary-dark);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .arrow-box {
    position: absolute;
    top: -1px;
    right: -1px;
    height: calc(100% + 1px);
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
    border-radius: 0 4px 4px 0;
    background: transparent;
    pointer-events: none;
    z-index: 1;
  }
  .arrow-box svg,
  .arrow-box path {
    transition: all 0.3s ease;
  }
  &.collapsed,
  &:hover {
    opacity: 1;
  }
  &.collapsed .dropdown-box {
    border-color: var(--color-bg-secondary);
  }
  &.collapsed .value-label {
    z-index: 10;
    border-radius: 4px 4px 0 0;
  }
  &.collapsed .arrow-box {
    background: var(--p-color-1);
    z-index: 11;
    border-radius: 0 4px 0 0;
  }
  &.collapsed .arrow-box svg {
    transform: rotate(180deg);
  }
  &.collapsed .arrow-box svg path {
    fill: var(--color-header-dropdown);
  }
  &.collapsed .dropdown-box__options {
    z-index: 10;
    opacity: 1;
  }
  &.collapsed .dropdown-box__options {
    max-height: calc(
      40px *
        ${({ itemCount, maxItemCount = 5 }) =>
          itemCount === 0
            ? 1
            : itemCount > maxItemCount
            ? maxItemCount
            : itemCount}
    );
    overflow: auto;
    pointer-events: auto;
  }

  @media (max-width: 480px) {
    .dropdown__mobile {
      pointer-events: auto;
    }
    &.collapsed .dropdown-box__options {
      max-height: 40px;
    }
  }
`

class Dropdown extends React.Component<IDropdownProps, IDropdownStates> {
  constructor(props: IDropdownProps) {
    super(props)
    this.state = {
      collapsed: false,
    }
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  wrapperRef

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.collapsed) {
        this.setState({ collapsed: false })
      }
    }
  }

  getSelectedOption = () => {
    const { options = [], value } = this.props
    if (!value || (options && options.length < 0)) return {}
    const selectedOption = options.find((option) => value === option.value)
    if (selectedOption) {
      return { value: selectedOption.value, label: selectedOption.label }
    } else return {}
  }

  getSortedOptions = () => {
    const { options, value, nosort } = this.props
    if (!options) return []
    const optionsArray = [...options]
    const selectedOptionIndex = options.findIndex((op) => op.value === value)
    optionsArray.splice(selectedOptionIndex, 1)
    if (nosort) return optionsArray
    const sortedOptions = optionsArray.sort((a, b) =>
      a.value < b.value ? -1 : a.value > b.value ? 1 : 0
    )
    return sortedOptions
  }

  collapseToggle = () =>
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }))

  onChangeDropdown = (value: string) => {
    const { onSelectOption } = this.props
    const selectedOption = this.getSelectedOption()
    this.collapseToggle()

    if (value !== selectedOption.value) {
      onSelectOption(value)
    }
  }

  render() {
    const { collapsed } = this.state
    const {
      label,
      options = [],
      value,
      onSelectOption,
      maxItemCount,
      className,
    } = this.props
    const selectedOption = this.getSelectedOption()
    return (
      <DropdownContainer
        itemCount={options.length}
        maxItemCount={maxItemCount}
        className={`${className} dropdown-container${
          !options && !value ? ' loading' : ''
        }${collapsed ? ' collapsed' : ''}`}
      >
        <Label>{label || ''}</Label>
        <div className="dropdown-box" ref={this.setWrapperRef}>
          <div className="arrow-box">
            <DropdownArrowIcon className="dropdown-arrow" />
          </div>
          <select
            onChange={(e) => onSelectOption(e.target.value)}
            className="dropdown__mobile"
            value={selectedOption.value}
          >
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
          <span className="value-label" onClick={this.collapseToggle}>
            {selectedOption.label || ''}
          </span>
          <div className="dropdown-box__options">
            {options &&
              options.length > 0 &&
              this.getSortedOptions().map((option) => (
                <span
                  className={`dropdown-box__option${
                    option.value === selectedOption.value ? ' selected' : ''
                  }`}
                  onClick={() => this.onChangeDropdown(option.value)}
                  key={option.value}
                  role="button"
                >
                  {option.label}
                </span>
              ))}
          </div>
        </div>
      </DropdownContainer>
    )
  }
}

export { Dropdown }
