import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { PopupContainer } from './Popup'
import { Button } from '../Button'

const StyledPopup = styled(PopupContainer)`
  .popup__content {
    min-height: 140px;
  }
  .popup__content > * {
    width: 100%;
    text-align: center;
  }
  .desc {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  .button-container > * {
    margin: 0 15px;
    margin: 0 15px;
    flex: 1;
    min-width: unset;
  }
`

interface IPopupDialogProps {
  className?: string
  title?: string
  visible?: boolean
  children?: ReactNode
  icon?: ReactNode
  width?: string
  onYesCallback: () => void
  onNoCallback: () => void
  yesText?: string
  noText?: string
  yesButtonColor?: string
  noButtonColor?: string
  subtitle?: string
  desc?: React.ReactNode
}

interface IPopupDialogStates {
  cachedProps: IPopupDialogProps
}

class PopupDialog extends React.Component<
  IPopupDialogProps,
  IPopupDialogStates
> {
  constructor(props: IPopupDialogProps) {
    super(props)
    this.state = {
      cachedProps: { ...props },
    }
  }

  render() {
    const {
      className,
      width = 'fit-content',
      visible,
      onNoCallback,
      onYesCallback,
    } = this.props
    const {
      yesText = 'Yes',
      noText = 'Cancel',
      yesButtonColor,
      noButtonColor,
      icon,
      title,
      subtitle,
      desc,
      children,
    } = this.state.cachedProps
    return (
      <StyledPopup
        className={`${className} popup-dialog`}
        title={title}
        onClose={onNoCallback}
        visible={visible}
        icon={icon}
        width={width}
        maxWidth={'400px'}
      >
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {desc && <div className="desc">{desc}</div>}
        {children ? children : null}
        <div className="button-container">
          <Button
            color={yesButtonColor}
            secondary
            label={yesText}
            onClick={onYesCallback}
          />
          <Button
            secondary
            color={noButtonColor}
            label={noText}
            onClick={onNoCallback}
            borderless
          />
        </div>
      </StyledPopup>
    )
  }
}

export { PopupDialog }
