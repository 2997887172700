import { createGlobalStyle, css } from 'styled-components'
// import { color, typography, spacing } from './styles'

const resetStyles = css`
  html,
  body {
    margin: 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dd,
  dl,
  dt,
  li,
  ol,
  ul,
  fieldset,
  form,
  label,
  legend,
  button,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: var(--medium);
    line-height: 1;
    font-family: inherit;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  ol,
  ul {
    list-style: none;
  }
  q:before,
  q:after,
  blockquote:before,
  blockquote:after {
    content: '';
  }
  html {
    font-size: var(--medium);
    -webkit-text-size-adjust: var(--medium);
    -ms-text-size-adjust: var(--medium);
  }
  a:focus {
    outline: thin dotted;
  }
  a:hover,
  a:active {
    outline: 0;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  nav,
  section {
    display: block;
  }
  audio,
  canvas,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
  }
  sub,
  sup {
    font-size: var(--small);
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
    -ms-interpolation-mode: bicubic;
  }
  button,
  input,
  select,
  textarea {
    font-size: var(--medium);
    margin: 0;
    vertical-align: baseline;
  }
  button,
  input {
    line-height: normal;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  button,
  input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    cursor: pointer;
    -webkit-appearance: button;
  }
  input[type='search'] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  input:focus {
    outline: unset;
  }
  textarea {
    overflow: auto;
    vertical-align: top;
  }
  button:active:focus {
    outline: unset;
  }
  svg:focus {
    outline: unset;
  }
`

export const bodyStyles = css`
  /* global styles */
  html,
  body {
    color: var(--color-text-primary);
    background: var(--color-bg-primary);
  }
  @keyframes fadeInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
      pointer-events: none;
    }
    60% {
      transform: translateX(0);
    }
    100% {
      pointer-events: auto;
      opacity: 1;
    }
  }
  @keyframes fadeInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
      pointer-events: none;
    }
    60% {
      transform: translateX(0);
    }
    100% {
      pointer-events: auto;
      opacity: 1;
    }
  }
  @keyframes fadeInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
    }
    60% {
      transform: translateY(0);
    }
    100% {
      pointer-events: auto;
      opacity: 1;
    }
  }
  @keyframes fadeInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
      pointer-events: none;
    }
    60% {
      transform: translateY(0);
    }
    100% {
      pointer-events: auto;
      opacity: 1;
    }
  }

  @keyframes fadeOutToLeft {
    0% {
      transform: translateX(0);
      pointer-events: auto;
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
      pointer-events: none;
    }
  }
  @keyframes fadeOutToRight {
    0% {
      transform: translateX(0);
      pointer-events: auto;
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateX(100%);
      pointer-events: none;
    }
  }
  @keyframes fadeOutToTop {
    0% {
      transform: translateY(0);
      pointer-events: auto;
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
    }
  }
  @keyframes fadeOutToBottom {
    0% {
      transform: translateY(0);
      pointer-events: auto;
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
      pointer-events: none;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    100% {
      opacity: 1;
      pointer-events: auto;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      pointer-events: auto;
    }
    100% {
      opacity: 0;
      pointer-events: none;
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInOutPartial {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeScaleIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes fadeScaleOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  .skeleton {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: inherit;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  .skeleton.loading {
    opacity: 1;
  }
  .skeleton.loading .skeleton-shine {
    animation: shine 1.6s linear infinite;
  }
  .skeleton-shine {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: linear-gradient(
      to right,
      #ffffff00 30%,
      #f2f2f210 50%,
      #ffffff00 70%
    );
    background-size: 200% auto;
  }
  @keyframes shine {
    0%,
    50% {
      background-position: 150% center;
    }
    100% {
      background-position: -50% center;
    }
  }
`

const themeStyles = css`
  html,
  body {
    font-family: D-DIN;
    height: 100%;
  }
  label {
    font-family: 'D-DIN Exp';
  }
  .sb-show-main {
    box-sizing: border-box;
    height: 100%;
  }
  #root {
    height: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'D-DIN Exp';
  }
  h1 {
    font-size: var(--xx-large);
    margin-bottom: 20px;
    line-height: 40px;
  }
  h2 {
    font-size: var(--x-large);
    margin-bottom: 20px;
  }
  h3 {
    font-size: var(--large);
  }
  p {
    font-family: D-DIN;
    font-size: var(--medium);
    color: var(--text-p-color-2);
    margin-bottom: 15px;
    line-height: normal;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 2px var(--color-bg-canvas);
    background-color: rgba(245, 130, 32, 0.9);
  }
`

export const GlobalStyle = createGlobalStyle`
:root {
  --color-text-primary: #c9d1d9;
  --color-label-text: #dbd9d6;
  --color-header-text: #f0f6fcb3;
  --color-header-fill: #f0f6fc;
  --color-header-dropdown: #202731;
  --color-printer-list-status-icon: #ffffff1a;
  --color-text-secondary: #8b949e;
  --color-brand-text-primary: #f58220;
  --color-brand-fill-primary: #ff9a47;
  --color-bg-primary: #070d12;
  --color-bg-primary-dark: #060709;
  --color-bg-primary-light: #4b5058;
  --color-bg-secondary: hsl(34deg 91% 45%);
  --color-bg-secondary-light: #ffca47;
  --color-bg-secondary-dark: #c66a00;
  --color-border-primary: #434c5680;
  --color-border-primary-hovered: #707e8f;
  --color-border-secondary: #434c5699;
  --color-button-bg: var(--color-brand-fill-primary);
  --color-button-bg-hovered: hsl(27deg 100% 64% / 80%);
  --color-button-text: #000;
  --color-button-secondary-border-width: 1px;
  --color-button-secondary-bg: transparent;
  --color-button-secondary-fill: var(--color-brand-fill-primary);
  --color-button-secondary-border-width: 1px;
  --color-button-secondary-text-hovered: #000;
  --color-sidebar-button-bg: transparent;
  --color-sidebar-button-text: var(--color-text-primary);
  --color-sidebar-button-fill: var(--color-text-primary);
  --color-sidebar-button-highlight: #ea7600;
  --color-link-button-text: #ffb71b;
  --color-input-text: #bababa;
  --color-bg-canvas: #070d12;
  --color-bg-canvas-inset: #192029;
  --color-header-bg: #161b22;
  --color-header-text: #cdd9e5;
  --color-header-hover-text: #cdd9e5b3;
  --color-popup-border: #2f3237;
  --color-input-bg: #01040980;
  --color-pwa-bg: #320c64;
  --color-text-help: #22aae9;
  --color-text-disabled: #8a939eb3;
  --color-help-button: #41c5dd;
  --color-popup-logo: #fff;
  --color-loader-mask: hsla(0, 0%, 0%, 0.95);
  --color-bg-mask: #000000b3;
  --color-green-primary: #40bf40;
  --color-green-secondary: #00b300;
  --color-red-primary: #e05252;
  --color-red-secondary: #cc3333;
  --p-color-red: #ff3e3e;
  --p-color-1: #ea7600;
  --text-p-color-1: #fff;
  --text-p-color-2: #dbd9d6;
  --bg-p-color-1: hsl(225, 10%, 8%);
  --fill-p-color-1: #f58220;
  --sidebar-bg-color: hsl(225, 10%, 12%);
  --popup-box-shadow: 1px 2px 8px 0px hsl(0deg 0% 0% / 75%);
  --xx-small: 6px;
  --x-small: 8px;
  --small: 14px;
  --medium: 16px;
  --large: 18px;
  --x-large: 24px;
  --xx-large: 30px;
  --padding-xs: 5px;
  --padding-sm: 10px;
  --padding-md: 15px;
  --padding-xl: 20px;
  --padding-xxl: 30px;
  --margin-xs: 5px;
  --margin-sm: 10px;
  --margin-md: 15px;
  --margin-xl: 20px;
  --margin-xxl: 30px;
  --icon-xs: 10px;
  --icon-sm: 20px;
  --icon-md: 40px;
  --icon-xl: 60px;
  --header-height: 56px;
  --header-mobile-height: 45px;
  @media (max-width: 600px) {
    --small: 12px;
    --medium: 14px;
  }
}
${resetStyles}
${bodyStyles}
${themeStyles}
`
