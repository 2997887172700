import React from 'react'
import styled from 'styled-components'

interface ISeparatorProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string
  width?: string
  horizontal?: boolean
  full?: boolean
  hidden?: boolean
}

export const Separator = styled(
  ({ className, horizontal, hidden }: ISeparatorProps) => (
    <div
      className={`${className} separator${horizontal ? ' horizontal' : ''}${
        hidden ? ' hidden' : ''
      }`}
    >
      <span className="separator-line" />
    </div>
  )
)`
  transition: all 0.3s ease;
  height: ${({ height = '0' }) => height};
  min-height: ${({ height = '0' }) => height};
  width: auto;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: relative;
  margin-left: unset;
  margin-right: unset;
  box-sizing: border-box;
  padding: 0 !important;
  margin: 0 !important;
  &.horizontal {
    min-width: unset;
    min-height: 100%;
    height: auto;
    width: ${({ width = '0' }) => width};
    min-width: ${({ width = '0' }) => width};
  }
  .separator-line {
    width: inherit;
    background: var(--color-border-primary);
    position: absolute;
    width: calc(100% - ${({ full }) => (full ? '0px' : '1em')});
    height: 1px;
  }
  &.horizontal .separator-line {
    width: 1px;
    height: calc(100% - ${({ full }) => (full ? '0px' : '1em')});
  }
  &.hidden {
    opacity: 0;
  }
`
