import React, { Component } from 'react'
import styled from 'styled-components'

import { Card } from '../Card'
import { LogoIcon } from '../Icon'
import { Input } from '../Input'
import { Button, LinkButton } from '../Button'
import { Container } from '../Container'
import { DelayedComponents } from '../DelayedComponents'
import { Spacing, Size } from '../../shared/styles'

const LoginStyledCard = styled(Card)`
  .form-wrapper {
    overflow: hidden;
  }
  .form-wrapper > * {
    transition: opacity 0.3s ease, transform 0.4s ease;
    width: 300px;
  }
  .reset-form {
    position: absolute;
    animation: fadeOutToRight 0.5s ease forwards;
    opacity: 0;
  }
  &.forgot-password .login-form {
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
  }
  &.forgot-password .reset-form {
    animation: fadeInFromRight 0.5s ease forwards 0.1s;
  }
`

export interface LoginFormStates {
  email?: string
  password?: string
}

export interface LoginFormProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  email?: string
  password?: string
  onSubmitLogin: (props: LoginFormStates) => void
  onSubmitReset: (email: string) => void
  onBackToLogin: () => void
  onForgotPassword: () => void
  forgetPassword?: boolean
  submitting?: boolean
}

class LoginForm extends Component<LoginFormProps, LoginFormStates> {
  state = {
    email: this.props.email || '',
    password: this.props.password || '',
  }

  getOnChangeCallback = (name: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target
    this.setState({ [name]: value })
  }

  onSubmitLogin = () => {
    const { email, password } = this.state
    this.props.onSubmitLogin({ email, password })
  }

  onSubmitReset = () => {
    const { email } = this.state
    this.props.onSubmitReset(email)
  }

  submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { forgetPassword } = this.props
    if (forgetPassword) {
      this.onSubmitReset()
    } else {
      this.onSubmitLogin()
    }
  }

  render() {
    const { email, password } = this.state
    const { submitting } = this.props
    return (
      <LoginStyledCard
        className={`login-form ${
          (this.props.forgetPassword && 'forgot-password') || ''
        }`}
        width="360px"
        mobileWidth="100%"
        mobileHeight="100%"
        mobileRoundedCorners={false}
      >
        <LogoIcon style={{ margin: '40px 0 50px 0' }} />
        <form onSubmit={this.submit} noValidate={true}>
          <Container
            className="form-wrapper"
            spacing={Spacing.none}
            paddingSize={Size.none}
          >
            <Container
              className="login-form"
              spacing={Spacing.none}
              paddingSize={Size.none}
              style={{ height: 'auto', minHeight: '310px' }}
            >
              <h2>Sign in to MyStructo</h2>
              <Input
                label="Email Address"
                value={email}
                onChange={this.getOnChangeCallback('email')}
                disabled={submitting}
              />
              <Input
                label="Password"
                value={password}
                onChange={this.getOnChangeCallback('password')}
                disabled={submitting}
                type="password"
              />
              <LinkButton
                label="Forgot password?"
                style={{ alignSelf: 'flex-end', marginBottom: '14px' }}
                onClick={this.props.onForgotPassword}
              />
              <Button
                type="submit"
                label="Login"
                style={{ marginTop: '20px' }}
                fullWidth
                disabled={submitting}
              />
            </Container>
            <DelayedComponents
              isMounted={Boolean(this.props.forgetPassword)}
              delayUnmount={300}
            >
              <Container
                className="reset-form"
                spacing={Spacing.none}
                paddingSize={Size.none}
              >
                <h2>Forgot Password?</h2>
                <p style={{ marginBottom: '30px' }}>
                  Enter the email address you used when you joined and we’ll
                  send you instructions to reset your password.
                </p>
                <Input
                  label="Email Address"
                  value={email}
                  onChange={this.getOnChangeCallback('email')}
                  disabled={submitting}
                />
                <Button
                  label="Send Reset Instructions"
                  type="submit"
                  style={{ marginTop: '20px', marginBottom: '14px' }}
                  fullWidth
                  disabled={submitting}
                />
                <LinkButton
                  label="Back to Login"
                  style={{ alignSelf: 'flex-start' }}
                  onClick={this.props.onBackToLogin}
                />
              </Container>
            </DelayedComponents>
          </Container>
        </form>
      </LoginStyledCard>
    )
  }
}

export { LoginForm }
