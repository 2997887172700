import React from 'react'
import styled from 'styled-components'

import { PrinterList } from './PrinterList'
import { DentaformIcon, VeloxIcon } from '../Icon'
import { Separator } from '../Separator'
import { Tag, TagGroup } from '../Tag'
import { ProgressBar } from '../ProgressBar'
import { FlexLineBreak } from '../FlexLineBreak'
import { convertMStoText } from '../../utils/time'
import humanize from '../../utils/humanize'

const JobList = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: var(--color-bg-canvas-inset);
  border-radius: 10px;
  width: 100%;
`

export interface IActiveJobListProps
  extends React.HTMLAttributes<HTMLDivElement> {
  activeJobs?: IActiveJobListItemProps[]
  loading?: boolean
}

interface IActiveJobSkeletonItem extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean
}

const ActiveJobSkeletonListItem = styled(
  ({ loading, className, ...rest }: IActiveJobSkeletonItem) => (
    <div
      className={`${className} active-job-list-item skeleton ${
        loading ? ' loading' : ''
      }`}
      {...rest}
    >
      <div className="skeleton-shine" />
      <div className="skeleton-printer">
        <div className="skeleton-printer__icon" />
        <div className="skeleton-printer__name" />
      </div>
      <div className="skeleton-printer-info">
        <span />
        <span />
        <span />
      </div>
      <div className="skeleton-printing-progress">
        <div className="skeleton-printing-status" />
        <div className="skeleton-printing-progress-bar" />
        <div className="skeleton-printing-time-remaining" />
      </div>
    </div>
  )
)`
  --skeleton-fill: var(--color-bg-primary-light);
  &&& {
    height: 174px;
    background: var(--color-bg-canvas-inset);
    z-index: 5;
    pointer-events: auto;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  .skeleton-printer {
    width: 131px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
  }
  .skeleton-printer__icon {
    width: 80px;
    background: var(--skeleton-fill);
    height: 140px;
    border-radius: 10px;
  }
  .skeleton-printer-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 380px;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .skeleton-printer-info > * {
    background: var(--skeleton-fill);
    height: 20px;
    width: 100%;
    border-radius: 5px;
    margin: 5px 0;
  }
  .skeleton-printing-progress {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    width: 240px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 20px;
    justify-content: center;
  }
  .skeleton-printing-status,
  .skeleton-printing-time-remaining {
    width: 110px;
    height: 20px;
    background: var(--skeleton-fill);
    border-radius: 5px;
    opacity: 0.8;
  }
  .skeleton-printing-time-remaining {
    width: 250px;
    max-width: 80%;
    height: 12px;
  }
  .skeleton-printing-progress-bar {
    height: 20px;
    width: 100%;
    border-radius: 100px;
    background: var(--p-color-1);
    opacity: 0.2;
    margin: 10px 0;
  }
  @media (max-width: 870px) {
    &&& {
      height: fit-content;
      flex-wrap: wrap;
    }
    .skeleton-printer {
      height: fit-content;
    }
    .skeleton-printer-info {
      flex: 1;
      height: fit-content;
    }
    .skeleton-printing-progress {
      flex-basis: 100%;
      height: fit-content;
      margin-top: -20px;
    }
    .skeleton-printer__icon {
      width: 70px;
      height: 100px;
    }
  }
  @media (max-width: 480px) {
    .skeleton-printer {
      width: 100px;
    }
  }
`

const ActiveJobList = styled(
  ({ activeJobs, loading, className, ...rest }: IActiveJobListProps) => (
    <div
      className={`${className} active-job-list${!activeJobs ? ' no-jobs' : ''}${
        loading ? ' loading' : ''
      }`}
      {...rest}
    >
      {loading && <ActiveJobSkeletonListItem loading={true} />}
      {!loading &&
        activeJobs &&
        activeJobs.map(({ fileName, ...jobsDataProps }, index) => (
          <React.Fragment key={fileName}>
            <ActiveJobListItem fileName={fileName} {...jobsDataProps} />
            {index !== activeJobs.length - 1 && (
              <Separator className="job-list-separator" full height="20px" />
            )}
          </React.Fragment>
        ))}
      <div className="no-jobs-indication">
        <span className="no-jobs-indication__text">No jobs running</span>
      </div>
    </div>
  )
)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: var(--color-bg-canvas-inset);
  border: 1px solid var(--color-border-primary);
  &.loading {
    background: unset;
  }
  border-radius: 10px;
  width: 100%;
  min-height: 80px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-primary);
  box-sizing: border-box;
  .no-jobs-indication {
    position: absolute;
    left: 50%;
    top: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    display: none;
  }
  .no-jobs-indication__text {
    font-size: var(--medium);
    opacity: 0.7;
  }
  &.no-jobs .no-jobs-indication {
    display: block;
  }
  @media (max-width: 870px) {
    &:not(.no-jobs) {
      background: unset;
      border-width: 0;
      > * {
        margin: 10px 0;
        background: var(--color-bg-canvas-inset);
        border-radius: 10px;
      }
      .job-list-separator {
        display: none;
      }
    }
  }
  @media (max-width: 800px) {
    &:not(.no-jobs) {
      > * {
        margin: 5px 0;
      }
    }
  }
`

enum PrinterTypes {
  dentaform = 'dentaform',
  velox = 'velox',
}

export interface IActiveJobListItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  fileName?: string
  printerType?: PrinterTypes
  printerName?: string
  customPrinterName?: string
  duration?: number
  layers?: number
  microns?: number
  resinName?: string
  status?: string
  progress?: number
}

const ActiveJobListItem = styled(
  ({
    fileName,
    printerType,
    printerName,
    customPrinterName,
    duration,
    layers,
    microns,
    resinName,
    status,
    progress,
    className,
  }: IActiveJobListItemProps) => {
    const totalTime =
      typeof duration === 'number' ? convertMStoText(duration) : undefined
    const timeLeft =
      typeof duration === 'number' && typeof progress === 'number'
        ? convertMStoText(((100 - progress) / 100) * Number(duration || 0))
        : undefined
    return (
      <div
        className={`active-job-list-item list-item list-item--type-active-job ${className}`}
      >
        <div className="list-item__printer-info">
          <div className="printer-info__icon">
            {(printerType && printerType === PrinterTypes.velox ? (
              <VeloxIcon />
            ) : (
              <DentaformIcon />
            )) || ''}
          </div>
          <div className="printer-info__name">
            {customPrinterName || printerName || ''}
          </div>
        </div>
        <Separator className="list-item__job-info__separator" horizontal />
        <div className="list-item__job-info">
          <span className="job-info__file-name">{fileName}</span>
          <TagGroup>
            {totalTime && <Tag>{totalTime}</Tag>}
            {layers && <Tag>{layers} Layers</Tag>}
            {microns && <Tag>{`${microns}um`}</Tag>}
            <FlexLineBreak className="job-info__resin-name__line-break" />
            {resinName && (
              <Tag className="job-info__resin-name" color="#F58220">
                {resinName}
              </Tag>
            )}
          </TagGroup>
        </div>
        <FlexLineBreak className="list-item__job-progress__line-break" />
        <Separator className="list-item__job-progress__separator" horizontal />
        <Separator className="list-item__job-progress__separator--vertical" />
        <div className="list-item__job-progress">
          <div className="job-progress__status">
            <span className="job-progress__status__name">{status ? humanize(status) : ''}</span>
            <span className="job-progress__status__percentage">
              {(progress && `${progress}%`) || ''}
            </span>
          </div>
          <ProgressBar value={progress} />
          <span className="job-progress__time-left">
            {(timeLeft && `${timeLeft} remaining`) || ''}
          </span>
        </div>
      </div>
    )
  }
)`
  height: auto;
  min-height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  .job-progress__status__name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: nowrap;
  }
  .job-info__resin-name,
  .job-progress__status__name {
    text-transform: capitalize;
  }
  > * {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    box-sizing: border-box;
  }
  .list-item__printer-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
  }
  .printer-info__name {
    margin-top: 15px;
    font-size: var(--large);
    color: var(--color-brand-text-primary);
    font-family: D-DIN;
    text-align: center;
  }
  .list-item__job-info {
    padding: 20px;
    box-sizing: border-box;
    flex: 2;
    min-width: 250px;
    max-width: 380px;
    display: flex;
    flex-direction: column;
  }
  .job-info__file-name {
    margin-bottom: 15px;
    word-break: break-word;
    font-size: var(--large);
  }
  .list-item__job-progress {
    flex: 1;
    min-width: 240px;
  }
  .job-progress__status {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-family: D-DIN;
    color: var(--color-brand-text-primary);
    font-size: var(--large);
  }
  .list-item__job-progress__line-break,
  .list-item__job-progress__separator--vertical {
    display: none;
  }
  .job-progress__time-left {
    font-size: var(--medium);
    opacity: 0.5;
    margin-top: 8px;
  }
  .printer-info__icon {
    opacity: 0.8;
  }

  @media (max-width: 870px) {
    .list-item__job-progress__separator,
    .list-item__job-info__separator {
      display: none;
    }
    .list-item__job-info {
      min-width: unset;
      max-width: unset;
    }
    .list-item__job-progress__line-break,
    .list-item__job-progress__separator--vertical {
      display: flex;
    }
  }
  @media (max-width: 480px) {
    .job-progress__status__name {
      max-width: 200px;
    }
    > * {
      padding: 10px;
    }
    .job-progress__time-left {
      font-size: var(--small);
    }
    .job-progress__status {
      font-size: var(--medium);
    }
    .job-info__resin-name__line-break {
      display: none;
    }
    .list-item__printer-info {
      width: 100px;
      padding: 10px;
      box-sizing: border-box;
    }
    .printer-info__name {
      font-size: var(--small);
    }
    .printer-info__icon svg {
      width: 30px;
    }
    .job-info__file-name {
      font-size: var(--medium);
    }
    .list-item__job-info {
      padding: 10px;
      justify-content: center;
    }
    .list-item__job-progress {
      min-width: unset;
    }
  }
`

export { JobList, ActiveJobList, ActiveJobListItem, PrinterTypes, PrinterList }
