import React from 'react'
import styled from 'styled-components'

import {
  PaddingSize,
  AlignHorizontal,
  AlignVertical,
  AlignValues,
  Size,
  AlignSelf,
  Spacing,
  SpacingValues,
} from '../../shared/styles'

export interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  paddingSize?: Size
  mobilePaddingSize?: Size
  borderRadius?: string
  mobileBorderRadius?: string
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
  targetMobileWidth?: string
  alignContentHorizontal?: AlignHorizontal
  alignContentVertical?: AlignVertical
  layoutHorizontally?: boolean
  alignSelf?: AlignSelf
  spacing?: Spacing
  main?: boolean
  full?: boolean
  pageContent?: boolean
  isMounted?: boolean
}

const Container = styled(
  ({
    paddingSize,
    mobilePaddingSize,
    borderRadius,
    mobileBorderRadius,
    width,
    height,
    mobileWidth,
    mobileHeight,
    targetMobileWidth,
    alignContentHorizontal,
    alignContentVertical,
    layoutHorizontally,
    alignSelf,
    spacing,
    className,
    main,
    full,
    pageContent,
    isMounted = true,
    ...rest
  }: IContainerProps) => (
    <div
      className={`${className}${(main && ' main') || ''}${
        (pageContent && ' page-content') || ''
      }${(layoutHorizontally && ' layout-horizontally') || ''}${
        (!isMounted && ' hidden') || ''
      }`}
      {...rest}
    />
  )
)`
  display: inline-flex;
  box-sizing: border-box;
  padding: var(--padding-xl);
  transition: all 0.3s ease;
  position: relative;
  flex-shrink: 0;
  flex-direction: ${({ layoutHorizontally }) =>
    layoutHorizontally ? 'row' : 'column'};
  ${({ layoutHorizontally }) =>
    layoutHorizontally ? 'justify-content' : 'align-items'}: ${({
    alignContentHorizontal,
  }) =>
    alignContentHorizontal
      ? AlignValues[alignContentHorizontal]
      : AlignValues.left};
  ${({ layoutHorizontally }) =>
    layoutHorizontally ? 'align-items' : 'justify-content'}: ${({
    alignContentVertical,
  }) =>
    alignContentVertical ? AlignValues[alignContentVertical] : AlignValues.top};
  padding: ${({ paddingSize, spacing = Spacing.xl, main }) =>
    paddingSize
      ? PaddingSize[paddingSize]
      : spacing === Spacing.none || main
      ? PaddingSize.xl
      : `${SpacingValues[spacing]}`};
  width: ${({ width, full }) => (width ? width : full ? '100%' : 'auto')};
  height: ${({ height, full }) => (height ? height : full ? '100%' : 'auto')};
  margin: ${({ alignSelf }) => (alignSelf ? alignSelf : '0')};
  overflow: auto;
  &.layout-horizontally {
    flex-direction: row;
    flex-wrap: wrap;
    ${({ alignContentVertical }) =>
      alignContentVertical === AlignVertical.top || !alignContentVertical
        ? 'align-content: flex-start;'
        : ''}
  }
  &.main {
    padding: 0;
    justify-content: flex-start;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) and (orientation: landscape) {
    &.main {
      justify-content: flex-start;
    }
  }
  @media (max-width: ${({ targetMobileWidth }) =>
      targetMobileWidth || '480px'}) {
    width: ${({ mobileWidth, width, full }) =>
      mobileWidth ? mobileWidth : width || full ? '100%' : 'auto'};
    height: ${({ mobileHeight, height, full }) =>
      mobileHeight ? mobileHeight : height || full ? '100%' : 'auto'};
    ${({ mobilePaddingSize }) =>
      (mobilePaddingSize && `padding: ${PaddingSize[mobilePaddingSize]};`) ||
      ''}
    ${({ mobileBorderRadius }) =>
      (mobileBorderRadius && `border-radius: ${mobileBorderRadius};`) || ''}
  }
`

interface IPageContainerProps extends IContainerProps {
  dashboardVisible?: boolean
  sidebarActive?: boolean
}

const PageContainer = styled(
  ({
    sidebarActive,
    dashboardVisible,
    className,
    ...rest
  }: IPageContainerProps) => (
    <Container
      className={`${className} page-container${
        sidebarActive ? ' sidebar-active' : ''
      }${(!dashboardVisible && ' no-dashboard') || ''}`}
      spacing={Spacing.none}
      paddingSize={Size.none}
      {...rest}
    />
  )
)`
  transition: all 0.3s ease;
  margin-left: 210px;
  margin-top: var(--header-height);
  width: 100%;
  max-width: calc(100% - 210px);
  flex: 1;
  width: 100%;
  @media (max-width: 600px) {
    margin-left: unset;
    margin-top: var(--header-mobile-height);
    width: 100%;
    max-width: unset;
    &.sidebar-active {
      transform: translateX(210px);
    }
  }
  &.no-dashboard {
    margin-left: unset;
    margin-top: 0;
    width: 100%;
    max-width: unset;
    transform: translateX(0);
  }
`

export { Container, PageContainer }
