import React, { useRef } from 'react'
import styled from 'styled-components'

import { getStepSize } from './../../utils/number'

const customMapping = (value, inMin, inMax, outMin, outMax) => {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

const pointsPositionsCalc = (points, w, h, options) =>
  points.map((e) => {
    const x = customMapping(e[0], options.xMin, options.xMax, 0, w)
    const y = customMapping(e[1], options.yMin, options.yMax, h, 0)
    return [isNaN(x) ? 0 : x, isNaN(y) ? 0 : y]
  })

const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0]
  const lengthY = pointB[1] - pointA[1]
  return {
    // eslint-disable-next-line no-restricted-properties
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  }
}

const controlPoint = (line, smooth) => (current, previous, next, reverse) => {
  const p = previous || current
  const n = next || current
  const l = line(p, n)

  const angle = l.angle + (reverse ? Math.PI : 0)
  const length = l.length * smooth
  const x = current[0] + Math.cos(angle) * length
  const y = current[1] + Math.sin(angle) * length
  return [x, y]
}

const bezierCommand = (controlPoint) => (point, i, a) => {
  const cps = controlPoint(a[i - 1], a[i - 2], point)
  const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
  const close = i === a.length - 1 ? ' z' : ''
  return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}${close}`
}

const svgPath = (points, command, h) => {
  const d = points.reduce(
    (acc, e, i, a) =>
      i === 0
        ? `M ${a[a.length - 1][0]},${h} L ${e[0]},${h} L ${e[0]},${e[1]}`
        : `${acc} ${command(e, i, a)}`,
    ''
  )

  return (
    <path
      stroke="#f58220"
      strokeWidth="2"
      fill="url(#linear-gradient)"
      d={`${d}`}
      className="svg-path"
    />
  )
}

const svgCircles = (points) =>
  points.reduce(
    (acc, point) => [...acc, { cx: `${point[0]}`, cy: `${point[1]}` }],
    []
  )

const defaultLineGraphData = [
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 0,
  },
  {
    value: 100,
  },
]

export const LineGraph = styled(
  ({
    containerWidth = 500,
    containerHeight = 250,
    smoothing = 0.1,
    suffix,
    loading,
    className,
    selectedIndex = 0,
    onSelectIndex,
    data,
    dataName,
    min,
    max,
    stepSize,
    ...rest
    // }: IGraphProps) => {
  }) => {
    const boxElement = useRef<HTMLDivElement & SVGForeignObjectElement>(null)
    const contentElement = useRef<HTMLDivElement>(null)

    // const [boxWidth, setBoxWidth] = useState(0)
    const boxWidth = 0

    // function AutoAdjustBoxWidth() {
    //   if (boxElement.current && contentElement.current) {
    //     const boxWidth = boxElement.current.offsetWidth
    //     const contentWidth = contentElement.current.offsetWidth
    //     if (boxWidth !== contentWidth) {
    //       setBoxWidth(contentWidth)
    //     }
    //   }
    // }

    const getGraphInfo = () => {
      const graphData = data && data.length > 0 ? data : defaultLineGraphData
      const graphDataIsDefault = !data || data.length === 0
      const dataLength = graphData.length

      max = max || [...graphData].sort((a, b) => b.value - a.value)[0].value
      max = max < 20 ? 20 : max

      const options = {
        yMin: -(max * 0.1),
        yMax: max + max * 0.1,
        xMin: 0,
        xMax: (dataLength - 1) * 100,
      }

      const points = graphData.map(({ value }, index) => [100 * index, value])

      const pointsPositions = pointsPositionsCalc(
        points,
        containerWidth,
        containerHeight,
        options
      )

      const bezierCommandCalc = bezierCommand(controlPoint(line, smoothing))
      const path = svgPath(pointsPositions, bezierCommandCalc, containerHeight)
      const circles = svgCircles(pointsPositions)
      stepSize = getStepSize(max, 10)

      const stepsNumber = max / stepSize + 1
      const steps = [] as [number, number][]
      for (let i = 0; i <= stepsNumber; i += 1) {
        steps[i] = [20, stepSize * i]
      }
      const stepsPosition = pointsPositionsCalc(
        steps,
        containerWidth,
        containerHeight,
        options
      )

      const startEndPositions = pointsPositionsCalc(
        [
          [0, 0],
          [options.xMax, options.yMax],
        ],
        containerWidth,
        containerHeight,
        options
      )

      const viewBoxWidth = containerWidth
      const viewBoxHeight = containerHeight
      const centerPosition = [viewBoxWidth / 2, 0]

      const selectedPointPosition = pointsPositions[selectedIndex]
      const selectedPointDiffX = selectedPointPosition[0] - centerPosition[0]
      const selectedPointValue = graphData[selectedIndex].value
      const selectedPointLabel = graphData[selectedIndex].label
      return {
        viewBoxWidth,
        viewBoxHeight,
        path,
        circles,
        stepsPosition,
        selectedPointPosition,
        selectedPointDiffX,
        selectedPointValue,
        selectedPointLabel,
        startEndPositions,
        graphData,
        graphDataIsDefault,
      }
    }

    const graphInfo = getGraphInfo()

    const {
      viewBoxWidth,
      viewBoxHeight,
      path,
      circles,
      stepsPosition,
      selectedPointPosition,
      selectedPointDiffX,
      selectedPointValue,
      selectedPointLabel,
      startEndPositions,
      graphData,
      graphDataIsDefault,
    } = graphInfo

    const isEmpty = graphDataIsDefault
    const graphDataWidth = !isEmpty ? 40 : 40
    let graphContainerWidth =
      startEndPositions[1][0] - startEndPositions[0][0] - 2
    graphContainerWidth = graphContainerWidth < 0 ? 0 : graphContainerWidth

    let graphDataContentWidth =
      startEndPositions[1][0] - startEndPositions[0][0]
    graphDataContentWidth =
      graphDataContentWidth === 0 ? 500 : graphDataContentWidth

    return (
      <div
        className={`${className} line-graph ${loading ? ' loading' : ''}${
          isEmpty ? ' empty' : ''
        }`}
      >
        <div
          className={`skeleton${loading ? ' loading' : ''}${
            isEmpty ? ' empty' : ''
          }`}
        >
          <div className="skeleton-shine" />
        </div>
        <svg
          viewBox={`-${graphDataWidth} 0 ${
            viewBoxWidth + graphDataWidth
          } ${viewBoxHeight}`}
        >
          {isEmpty && (
            <foreignObject
              ref={boxElement}
              className={`point-info`}
              x={String(
                (viewBoxWidth + graphDataWidth) / 2 -
                  boxWidth / 2 -
                  graphDataWidth
              )}
              y={String(viewBoxHeight / 2 - 50)}
              width={`${boxWidth}`}
              height="100"
              overflow="visible"
            >
              <div ref={contentElement} className={`point-info__container`}>
                <span className="point-info__label">
                  {isEmpty ? 'No records found' : selectedPointLabel}
                </span>
              </div>
            </foreignObject>
          )}
          {!graphDataIsDefault && (
            <>
              <defs>
                <clipPath id={`${dataName}-clip-path`}>
                  <rect
                    id="graph-container"
                    x={String(startEndPositions[0][0] + 1)}
                    width={String(graphContainerWidth)}
                    height={'calc(100% - 2px)'}
                    rx="5"
                    fill="#fff"
                  />
                </clipPath>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1">
                  <stop offset="0" stopColor="#f58220" />
                  <stop offset="1" stopColor="#4f1c1c" stopOpacity="0" />
                </linearGradient>
              </defs>
              {stepsPosition &&
                stepsPosition.map((val, index) => (
                  <React.Fragment key={`steps-val-${val}`}>
                    <text
                      className="steps"
                      x="-20"
                      y={`${val[1]}`}
                      textAnchor="end"
                    >
                      {index * stepSize}
                    </text>
                    <path
                      d={`M-15,${val[1]} h5`}
                      opacity="0.5"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                  </React.Fragment>
                ))}
              <g className="graph-data-content">
                <rect
                  width={graphDataContentWidth}
                  height={'100%'}
                  x={`${startEndPositions[0][0]}`}
                  y={`${startEndPositions[1][1]}`}
                  rx="5"
                  fill="var(--color-bg-canvas)"
                />
                <g clipPath={`url(#${dataName}-clip-path)`}>{path}</g>
                {circles &&
                  circles.map(({ cx, cy }, index) => (
                    <circle
                      key={`point-${index}-${graphData[index].label}-point`}
                      cx={cx}
                      cy={cy}
                      className={`svg-circles${
                        index === selectedIndex ? ' active' : ''
                      }`}
                      onMouseOver={() => onSelectIndex(index)}
                    />
                  ))}
                {circles &&
                  circles.map(({ cx, cy }, index) => (
                    <text
                      key={`x-${index}-${graphData[index].label}-point`}
                      x={cx}
                      y={viewBoxHeight + 15}
                      textAnchor="end"
                      className="steps"
                    >
                      {graphData[index].xValue}
                    </text>
                  ))}
                <foreignObject
                  ref={boxElement}
                  className={`point-info`}
                  x={String(
                    selectedPointPosition[0] -
                      selectedPointDiffX * 0.15 -
                      100 / 2
                  )}
                  y={String(
                    selectedPointPosition[1] - graphDataWidth - 100 / 2
                  )}
                  style={{ overflow: 'visible' }}
                  height="100"
                  width="100"
                >
                  <div ref={contentElement} className={`point-info__container`}>
                    <span className="point-info__label">
                      {isEmpty ? 'No records found' : selectedPointLabel}
                    </span>
                    <span className="point-info__value">
                      {!isEmpty && selectedPointValue}
                    </span>
                    <span className="point-info__suffix">{suffix || ''}</span>
                  </div>
                </foreignObject>
              </g>
            </>
          )}
          {loading ||
            (graphDataIsDefault && (
              <rect
                className="loading-placeholder"
                width={`${viewBoxWidth + graphDataWidth}`}
                height={`100%`}
                x={`-${graphDataWidth}`}
                rx="5"
                fill="var(--color-bg-canvas)"
              />
            ))}
        </svg>
      </div>
    )
  }
)`
  position: relative;
  width: 100%;
  height: auto;
  .skeleton {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--color-bg-canvas);
    border-radius: 5px;
    z-index: 1;
    overflow: hidden;
  }
  &.empty svg {
    opacity: 1;
    background: var(--color-bg-canvas);
  }
  &.loading svg {
    opacity: 0;
  }
  &.loading .graph-data-content {
    opacity: 0;
  }
  .loading-placeholder {
    transition: opacity 0.3s ease;
    opacity: 0;
  }
  &.loading .loading-placeholder {
    opacity: 1;
  }
  text {
    transform: translateY(5px);
  }
  .steps {
    font-size: var(--medium);
    fill: var(--color-text-primary);
    opacity: 0.5;
    transition: all 0.3s ease;
  }
  &.loading .steps {
    opacity: 0;
  }
  .point-info {
    pointer-events: none;
    transition: all 0.3s ease, width 0s;
    text-align: center;
  }
  &.empty .point-info {
    transition: unset;
  }
  .point-info__container {
    font-family: D-DIN;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    background: var(--color-bg-canvas-inset);
    border-radius: 10px;
    border: 1px solid var(--color-brand-fill-primary);
    width: max-content;
    box-shadow: var(--popup-box-shadow);
  }
  .point-info__label {
    text-transform: capitalize;
    opacity: 1;
    font-family: D-DIN;
    font-size: var(--medium);
    display: block;
    min-width: max-content;
    margin-bottom: 5px;
  }
  &.empty .point-info__label {
    margin-bottom: unset;
  }
  .point-info__value {
    font-size: var(--large);
    margin-right: 8px;
  }
  .point-info__suffix {
    font-family: D-DIN;
    dx: 3;
    font-size: var(--medium);
    opacity: 0.7;
  }
  .line-graph__bg {
    position: absolute;
    left: 0;
    top: 0;
    background: var(--color-bg-canvas-inset);
    border-radius: 5px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    pointer-events: none;
    z-index: 0;
  }
  svg {
    z-index: 1;
    position: relative;
    transition: all 0.3s ease;
    overflow: visible;
  }
  svg path {
    transition: all 0.3s ease;
  }
  .svg-circles {
    fill: var(--color-brand-text-primary);
    stroke: black;
    stroke-width: 3px;
    opacity: 0.8;
    transition: all 0.3s ease;
    r: 7;
  }
  .svg-circles:hover {
    opacity: 1;
    cursor: pointer;
    r: 9;
  }
  .svg-circles:hover:not(.active) ~ .point-info {
    opacity: 0.5;
  }
  .svg-circles.active {
    opacity: 1;
    fill: black;
    stroke: var(--color-brand-text-primary);
  }
  @media (max-width: 600px) {
    margin-left: -10px;
    width: calc(100% + 20px);
    padding-bottom: 24px;
    .svg-circles {
      r: 5;
    }
    .svg-circles:hover {
      r: 7;
    }
    svg {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`
