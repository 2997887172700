import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Container, IContainerProps } from '../Container'
import { CloseIcon } from '../Icon'
import { isObjectEqual, isEmpty } from '../../utils/object'

export const PopupContainer = styled(
  ({ className, visible, onClose, icon, title, children }) => (
    <div className={`${className} popup${!visible ? ' hidden' : ''}`}>
      <div className="popup__bg-mask" onClick={onClose} />
      <div className="popup__inner-container">
        <div className="popup__header">
          <div className="popup__title">
            <span className="popup__header-icon">{icon || ''}</span>
            {title || 'title'}
          </div>
          <div
            tabIndex={99}
            role="button"
            className="popup__header__close-button"
            onClick={onClose}
          >
            <CloseIcon />
          </div>
        </div>
        <Container className="popup__content">{children}</Container>
      </div>
    </div>
  )
)`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  overflow: visible;
  pointer-events: none;
  z-index: 50;
  .popup__bg-mask {
    background: rgb(0 0 0 / 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    pointer-events: auto;
    animation: fadeIn 0.3s ease forwards;
  }
  .popup__inner-container {
    top: 36px;
    z-index: 1;
    position: absolute;
    margin: auto;
    background: var(--color-bg-canvas-inset);
    width: ${({ width }) => (width ? width : 'fit-content')};
    transition: all 0.3s ease;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    animation: fadeScaleIn 0.3s ease forwards;
    max-width: calc(100% - 30px);
    min-width: 220px;
    box-shadow: var(--popup-box-shadow);
  }
  .popup__header {
    height: 45px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    user-select: none;
    background: var(--color-bg-primary);
    border-bottom: 2px solid var(--color-bg-secondary);
  }
  .popup__title {
    position: absolute;
    max-width: calc(100% - 80px);
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--large);
    font-family: D-DIN;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--color-header-fill);
  }
  .popup__header-icon,
  .popup__header__close-button {
    padding: 0 15px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .popup__header-icon {
    position: absolute;
    left: -35px;
    top: 0;
    padding: 0;
  }
  .popup__header__close-button {
    transition: background 0.3s ease;
    cursor: pointer;
    position: absolute;
    right: 0;
    border-radius: 0 10px 0 0;
  }
  .popup__header__close-button svg {
    transition: all 0.3s ease;
    opacity: 0.7;
  }
  .popup__header__close-button:active svg {
    transform: scale(1.3);
  }
  .popup__header__close-button:focus,
  .popup__header__close-button:hover {
    outline: unset;
    background: var(--color-bg-primary-dark);
  }
  .popup__header__close-button:focus svg,
  .popup__header__close-button:hover svg {
    opacity: 1;
  }
  .popup__content {
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
    background: var(--color-bg-canvas-inset);
    ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    border-radius: 0 0 10px 10px;
    max-height: calc(100vh - 150px);
  }
  &.hidden .popup__bg-mask {
    animation: fadeOut 0.3s ease forwards;
  }
  &.hidden .popup__inner-container {
    animation: fadeScaleOut 0.3s ease forwards;
  }
`

interface IPopupProps extends IContainerProps {
  className?: string
  title?: string
  visible?: boolean
  onClose?: () => void
  children: ReactNode
  icon?: ReactNode
  width?: string
  maxWidth?: string
}

interface IPopupStates {
  cachedProps?: {
    title?: string
    children: ReactNode
    icon?: ReactNode
  }
}

class Popup extends React.Component<IPopupProps, IPopupStates> {
  constructor(props) {
    super(props)
    this.state = {
      cachedProps: {
        title: props.title,
        children: props.children,
        icon: props.icon,
      },
    }
  }
  static getDerivedStateFromProps(props: IPopupProps, state: IPopupStates) {
    const newState = {} as IPopupStates
    if (
      props.visible &&
      !isObjectEqual(state.cachedProps, {
        title: props.title,
        children: props.children,
        icon: props.icon,
      })
    ) {
      newState.cachedProps = { ...props }
    }

    return isEmpty(newState) ? null : newState
  }

  render() {
    const { className, width, maxWidth, visible, onClose } = this.props
    const { title, children, icon } = this.state.cachedProps!

    return (
      <PopupContainer
        className={className}
        width={width}
        maxWidth={maxWidth}
        visible={visible}
        onClose={onClose}
        title={title}
        icon={icon}
      >
        {children}
      </PopupContainer>
    )
  }
}

export { Popup }
