import React from 'react'
import styled from 'styled-components'

import {
  PaddingSize,
  AlignHorizontal,
  AlignVertical,
  AlignValues,
  Size,
  AlignSelf,
  AlignSelfValues,
  Spacing,
  SpacingValues,
} from '../../shared/styles'

export interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  inverted?: boolean
  hideBackground?: boolean
  roundedCorners?: boolean
  mobileRoundedCorners?: boolean
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
  paddingSize?: Size
  mobilePaddingSize?: Size
  mobileBorderRadius?: string
  alignContentHorizontal?: AlignHorizontal
  alignContentVertical?: AlignVertical
  layoutHorizontally?: boolean
  alignSelf?: AlignSelf
  spacing?: Spacing
}

const Card = styled(
  ({
    inverted,
    hideBackground,
    roundedCorners = true,
    mobileRoundedCorners = true,
    className,
    width,
    height,
    mobileWidth,
    mobileHeight,
    paddingSize,
    mobilePaddingSize,
    mobileBorderRadius,
    alignContentHorizontal,
    alignContentVertical,
    layoutHorizontally,
    alignSelf,
    ...rest
  }: ICardProps) => (
    <div
      className={`Card ${className}${(hideBackground && ' no-bg') || ''}${
        (roundedCorners && ' rounded') || ''
      }${(inverted && ' inverted') || ''}${
        (layoutHorizontally && ' layout-horizontally') || ''
      }`}
      {...rest}
    />
  )
)`
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: all 0.3s ease;
  box-shadow: var(--popup-box-shadow);
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-border-primary);
  position: relative;
  ${(props) =>
    props.layoutHorizontally ? 'justify-content' : 'align-items'}: ${(props) =>
    props.alignContentHorizontal
      ? AlignValues[props.alignContentHorizontal]
      : AlignValues.center};
  ${(props) =>
    props.layoutHorizontally ? 'align-items' : 'justify-content'}: ${(props) =>
    props.alignContentVertical
      ? AlignValues[props.alignContentVertical]
      : AlignValues.top};

  padding: ${(props) =>
    props.paddingSize ? PaddingSize[props.paddingSize] : PaddingSize.xl};
  background: ${(props) =>
    props.inverted
      ? 'var(--color-bg-canvas-inset)'
      : 'var(--color-bg-canvas-inset)'};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  min-height: auto;
  && {
    margin: ${(props) =>
      props.alignSelf
        ? AlignSelfValues[props.alignSelf]
        : AlignSelfValues.none};
  }
  & > *:not(:first-child) {
    ${({ spacing = Spacing.none, layoutHorizontally }) =>
      spacing === Spacing.none
        ? ''
        : `${layoutHorizontally ? 'margin-left: ' : 'margin-top: '}${
            spacing ? SpacingValues[spacing] : SpacingValues.xl
          }`};
  }
  &.rounded {
    border-radius: 10px;
  }
  &.layout-horizontally {
    flex-direction: row;
  }
  @media (max-width: 480px) {
    width: ${(props) =>
      props.mobileWidth ? props.mobileWidth : props.width || 'auto'};
    height: ${(props) =>
      props.mobileHeight ? props.mobileHeight : props.height || 'auto'};
    ${({ mobilePaddingSize }) =>
      (mobilePaddingSize && `padding: ${PaddingSize[mobilePaddingSize]};`) ||
      ''}
    ${({ mobileBorderRadius }) =>
      (mobileBorderRadius && `border-radius: ${mobileBorderRadius};`) ||
      ''}
    &.rounded {
      ${({ mobileRoundedCorners = true }) =>
        `border-radius: ${mobileRoundedCorners ? '10px' : '0'};`}
    }
  }
`

export { Card }
