import React from 'react'
import styled from 'styled-components'

import { BurgerMenuIcon, LogoIcon, ProfileIcon } from '../Icon'
import { Container } from '../Container'

import { AlignHorizontal, AlignVertical, Spacing } from '../../shared/styles'

const StyledBurgerMenu = styled(BurgerMenuIcon)`
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  @media (max-width: 600px) {
    pointer-events: auto;
    opacity: 1;
    position: absolute;
    left: 20px;
    cursor: pointer;
  }
`

const StyledLogoIcon = styled(LogoIcon)`
  position: absolute;
  transition: all 0.3s ease;
  cursor: pointer;
  left: 30px;
  z-index: 1;
  width: 120px;
  @media (max-width: 600px) {
    width: 82px;
    left: 50%;
    transform: translateX(-50%);
    &.sidebar-active {
      left: 210px;
      transform: translateX(calc(-100% - 60px));
    }
  }
`

const StyledProfileIcon = styled(ProfileIcon)`
  cursor: pointer;
  pointer-events: auto;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`

export const StyledAnchor = styled.a`
  color: var(--color-text-primary);
  opacity: 0.7;
  transition: opacity 0.3s ease;
  margin: 10px;
  text-decoration: auto;
  text-transform: capitalize;
  &:hover {
    opacity: 1;
  }
`

interface IUserRoleBlock extends React.HTMLAttributes<HTMLDivElement> {
  role?: string
  name?: string
}

const UserRoleBlock = styled(({ role, name, ...props }: IUserRoleBlock) => (
  <div {...props}>
    <span className="role" title={role}>
      {role || ' '}
    </span>
    <span className="name" title={name}>
      {name || ' '}
    </span>
  </div>
))`
  font-size: var(--medium);
  letter-spacing: 1px;
  z-index: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 100px;
  white-space: nowrap;
  user-select: none;
  .role {
    font-weight: bold;
    margin-bottom: 3px;
  }
  .name {
    opacity: 0.7;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 600px) {
    font-size: var(--small);
    .role {
      margin-bottom: 1px;
    }
  }
`

export interface IHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean
  onSidebarClick?: () => void
  onLogoClick?: () => void
  onLogout?: () => void
  sidebarActive?: boolean
  links?: ILink[]
  name?: string
  role?: string
}

interface IHeaderStates {
  profilePanelCollapse?: boolean
}

class Header extends React.Component<IHeaderProps, IHeaderStates> {
  constructor(props: IHeaderProps) {
    super(props)
    this.state = {
      profilePanelCollapse: false,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  wrapperRef

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.profilePanelCollapse) {
        this.setState({ profilePanelCollapse: false })
      }
    }
  }

  onProfileClick = () =>
    this.setState((prevState) => ({
      profilePanelCollapse: !prevState.profilePanelCollapse,
    }))

  logout = () => {
    this.setState({ profilePanelCollapse: false }, this.props.onLogout)
  }

  render() {
    const {
      visible,
      onSidebarClick,
      sidebarActive,
      links,
      name,
      role,
    } = this.props
    const { profilePanelCollapse } = this.state
    return (
      <HeaderContainer
        className="header-container"
        links={links}
        visible={visible}
        onSidebarClick={onSidebarClick}
        sidebarActive={sidebarActive}
        onProfileClick={this.onProfileClick}
        profileCollapse={profilePanelCollapse}
        onLogout={this.logout}
        onLogoClick={this.props.onLogoClick}
        dropdownWrapperRef={this.setWrapperRef}
        name={name}
        role={role}
      />
    )
  }
}

export type ILink = {
  label: string
  url: string
}

interface IHeaderContainerProps extends IHeaderProps {
  onProfileClick?: () => void
  links?: ILink[]
  profileCollapse?: boolean
  dropdownWrapperRef: any
}

const HeaderContainer = styled(
  ({
    className,
    visible,
    onSidebarClick,
    onProfileClick,
    onLogoClick,
    sidebarActive,
    profileCollapse,
    onLogout,
    dropdownWrapperRef,
    links,
    name,
    role,
  }: IHeaderContainerProps) => (
    <Container
      height="var(--header-height)"
      mobileHeight="var(--header-mobile-height)"
      targetMobileWidth="600px"
      layoutHorizontally={true}
      alignContentVertical={AlignVertical.center}
      alignContentHorizontal={AlignHorizontal.spaceBetween}
      spacing={Spacing.none}
      className={`${className}${visible ? ' visible' : ''}${
        sidebarActive ? ' sidebar-active' : ''
      }`}
    >
      <div className="sidebar-top-block" />
      <StyledBurgerMenu
        active={sidebarActive}
        onClick={onSidebarClick}
        tabIndex={-1}
      />
      <StyledLogoIcon
        onClick={onLogoClick}
        className={sidebarActive ? ' sidebar-active' : ''}
      />
      <div className="links">
        {links &&
          links.map(({ label, url }) => (
            <StyledAnchor key={url} title={label} target="_blank" href={url}>
              {label}
            </StyledAnchor>
          ))}
      </div>
      {(name || role) && <UserRoleBlock name={name} role={role} />}
      <div className="profile-wrapper">
        <StyledProfileIcon onClick={onProfileClick} />
        <div
          className={`profile-dropdown-panel${
            profileCollapse ? ' active' : ''
          }`}
          ref={dropdownWrapperRef}
        >
          <div
            onClick={onLogout}
            className="profile-dropdown-panel__logout-btn"
          >
            Log Out
          </div>
        </div>
      </div>
    </Container>
  )
)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 var(--padding-xl);
  background: var(--color-header-bg);
  box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 40%);
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100%);
  overflow: visible;
  width: 100%;
  justify-content: flex-end;
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  .sidebar-top-block {
    transition: all 0.3s ease;
    position: absolute;
    background: hsl(225deg 10% 12% / 50%);
    left: 0;
    top: 0;
    width: 210px;
    height: 100%;
    transform: translateX(-100%);
    opacity: 0;
    border-right: 1px solid var(--color-border-secondary);
    background: var(--color-bg-canvas-inset);
    box-sizing: border-box;
  }
  .profile-wrapper {
    position: relative;
  }
  .profile-dropdown-panel {
    position: absolute;
    bottom: -45px;
    width: 130px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 1px 7px 14px -5px #000;
    overflow: hidden;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10%);
    user-select: none;
    cursor: pointer;
  }
  .profile-dropdown-panel.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  .profile-dropdown-panel > * {
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    font-size: var(--medium);
    background: var(--color-header-dropdown);
    color: var(--color-text-primary);
  }
  .profile-dropdown-panel > *:hover {
    background: var(--p-color-1);
    color: var(--color-text-primary);
  }
  &.sidebar-active .sidebar-top-block {
    transform: translateX(0%);
    opacity: 1;
  }
  .links {
    margin-right: 30px;
    text-transform: capitalize;
  }
  @media (min-width: 601px) {
    &.visible .sidebar-top-block {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    .links {
      display: none;
    }
  }
`

export { Header }
