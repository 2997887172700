import React from 'react'
import styled from 'styled-components'

interface ITypographyProps extends React.HTMLAttributes<HTMLHeadingElement> {
  color?: string
  size?: string
  secondary?: boolean
}
const Title = styled(
  ({
    color,
    size,
    className,
    secondary,
    children,
    ...rest
  }: ITypographyProps) => (
    <h1 className={`typography ${className}`} {...rest}>
      {children}
    </h1>
  )
)`
  font-size: var(--xx-large);
  font-family: D-DIN;
  font-weight: bold;
  @media (max-width: 480px) {
    font-size: var(--x-large);
    line-height: var(--x-large);
  }
`

const Subtitle = styled(
  ({
    color,
    size,
    className,
    secondary,
    children,
    ...rest
  }: ITypographyProps) => (
    <h2 className={`typography ${className}`} {...rest}>
      {children}
    </h2>
  )
)`
  font-size: var(--large);
  font-family: D-DIN;
  font-weight: normal;
  @media (max-width: 480px) {
    font-size: var(--medium);
  }
`

const Paragraph = styled(
  ({
    color,
    size,
    className,
    secondary,
    children,
    ...rest
  }: ITypographyProps) => (
    <p className={`typography ${className}`} {...rest}>
      {children}
    </p>
  )
)`
  font-size: var(--medium);
  font-family: D-DIN;
  font-weight: bold;
`

export { Title, Subtitle, Paragraph }
