import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Container, IContainerProps } from '../Container'
import { Spacing } from '../../shared/styles'

interface IStatisticCard extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
  icon?: ReactNode
  iconColor?: string
  value?: string
  label?: string
  loading?: boolean
}

const StatisticCard = styled(
  ({
    color,
    icon,
    iconColor,
    value,
    label,
    className,
    loading = false,
    ...rest
  }: IStatisticCard) => (
    <div className={`${className} statistic-card`} {...rest}>
      <div className="icon-circle">
        <div className="icon-circle__bg" />
        <span className="icon-circle__icon">{icon}</span>
      </div>
      <div className="info">
        <div className="value" title={value}>
          {value}
        </div>
        <div className="label" title={label}>
          {label}
        </div>
      </div>
      <div className={`skeleton${loading ? ' loading' : ''}`}>
        <div className="skeleton-shine" />
        <div className="skeleton-avatar" />
        <div className="line-container">
          <div className="skeleton-line" />
          <div className="skeleton-line" />
        </div>
      </div>
    </div>
  )
)`
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  min-height: 100px;
  overflow: auto;
  align-items: center;
  background: var(--color-bg-canvas-inset);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-border-primary);
  transition: all 0.3s ease;
  position: relative;
  .icon-circle {
    min-width: 55px;
    height: 55px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 20px;
  }
  .icon-circle__bg {
    background: ${({ color }) => color || 'var(--color-brand-text-primary)'};
    opacity: 0.2;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .icon-circle__icon {
    line-height: 0;
  }
  .icon-circle__icon svg,
  .icon-circle__icon svg path,
  .icon-circle__icon svg g {
    fill: ${({ color }) => color || 'var(--color-brand-text-primary)'};
    width: 25px;
    height: auto;
  }
  .info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .label {
    font-size: var(--medium);
    opacity: 0.5;
  }
  .value {
    white-space: nowrap;
    overflow: hidden;
    font-size: var(--large);
    margin-bottom: 7px;
    text-overflow: ellipsis;
  }
  .skeleton .skeleton-avatar {
    width: 55px;
    height: 55px;
    border-radius: 100px;
    background: var(--color-bg-primary-light);
    margin: var(--padding-xl);
  }
  .skeleton .line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
  }
  .skeleton .line-container > * {
    height: 15px;
    background: var(--color-bg-primary-light);
    border-radius: 5px;
    flex: 1;
    flex-basis: 100%;
    margin: 5px var(--padding-xl) 5px 0;
  }
  @media (max-width: 1033px) {
    min-width: unset;
  }
  @media (max-width: 710px) {
    padding: var(--padding-sm);
    min-height: 80px;
    .icon-circle {
      min-width: 35px;
      height: 35px;
      margin-right: 10px;
    }
    .skeleton .skeleton-avatar {
      width: 35px;
      height: 35px;
      margin: 10px;
    }
    .skeleton .line-container > * {
      border-radius: 4px;
      margin: 2px 10px 2px 0;
    }
    .icon-circle svg {
      width: 15px;
    }
    .value {
      margin-bottom: 2px;
      font-size: var(--medium);
    }
    .label {
      font-size: var(--small);
    }
  }
`

const StatisticCardGroup = styled(({ children, ...rest }: IContainerProps) => (
  <Container layoutHorizontally={true} spacing={Spacing.xl} {...rest}>
    {children}
  </Container>
))`
  overflow: visible;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: var(--margin-xl);
  padding: 0;
  .flex-placeholder {
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
    border-top-width: 0;
    border-bottom-width: 0;
  }
  @media (max-width: 710px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
`

export { StatisticCard, StatisticCardGroup }
