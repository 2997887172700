import React from 'react'
import styled from 'styled-components'

interface ILoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean
  text?: string
}

const SpinnerLoader = styled(({ isMounted, ...rest }) => (
  <div {...rest}>
    <div className="spinner" />
  </div>
))`
  --spinner-weight: 3px;
  --spinner-size: 80%;
  --spinner-distance: 2px;
  --spinner-color: var(--p-color-1);
  position: relative;
  width: 60px;
  height: 60px;
  .spinner {
    width: 40px;
    height: 40px;
  }
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    border-radius: 50%;
    border-width: var(--spinner-weight);
    border-style: solid;
    border-color: var(--spinner-color) transparent;
    animation: rotate_1 2.2s infinite linear forwards;
  }

  .spinner::before {
    content: '';
    position: absolute;
    top: calc(var(--spinner-weight) + var(--spinner-distance));
    left: calc(var(--spinner-weight) + var(--spinner-distance));
    right: calc(var(--spinner-weight) + var(--spinner-distance));
    bottom: calc(var(--spinner-weight) + var(--spinner-distance));
    border-radius: 50%;
    border-width: var(--spinner-weight);
    border-style: solid;
    border-color: var(--spinner-color) transparent;
    animation: rotate_2 0.8s infinite linear forwards reverse;
  }

  @keyframes rotate_1 {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes rotate_2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = styled(({ visible, text, className }: ILoaderProps) => (
  <div className={`${className} loader${!visible ? ' hidden' : ''}`}>
    <div className="loader__mask" />
    <div className="loader__spinner-container">
      <SpinnerLoader />
    </div>
    <span className="loader__text">{text || 'Please wait...'}</span>
  </div>
))`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fadeIn 0.3s ease forwards;
  .loader__mask {
    background: hsla(0, 0%, 0%, 0.85);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .loader__text {
    margin-top: 10px;
  }
  .loader__text,
  .loader__spinner-container {
    animation: fadeInOutPartial 2.2s infinite ease forwards;
  }
  &.hidden {
    animation: fadeOut 0.3s ease forwards;
  }
`

export { Loader, SpinnerLoader }
