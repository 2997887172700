import React from 'react'
import styled from 'styled-components'

import { SmallButton } from '../Button/index'

const SidebarPWAPromoContainer = styled.div`
  background: var(--color-pwa-bg);
  padding: 12px 15px;
  p {
    text-align: left;
    line-height: 17px;
    font-size: var(--small);
    font-weight: bold;
    letter-spacing: 0.3px;
  }
  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .text-button {
    background: none;
    color: inherit;
    outline: unset;
    font-size: var(--small);
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  .install-button {
    margin-left: 15px;
    border-radius: 30px;
    color: var(--color-bg-primary-dark);
    font-size: var(--small);
  }
  .text-button:hover {
    opacity: 0.9;
  }
  .text-button:active {
    opacity: 0.5;
  }
  p {
    margin-bottom: 5px;
  }
`

export default function ({
  onInstall,
  onIgnore,
  visible,
}: {
  onInstall?: () => void
  onIgnore?: () => void
  visible?: boolean
}) {
  if (!visible) return null
  return (
    <SidebarPWAPromoContainer>
      <p>
        Install our free web app on your homescreen to have quick access to your
        dashboard
      </p>
      <div className="btn-container">
        <button className="text-button" onClick={onIgnore}>
          Not right now
        </button>
        <SmallButton
          className="install-button"
          label="Install"
          secondary
          active
          onClick={onInstall}
        />
      </div>
    </SidebarPWAPromoContainer>
  )
}
