import React from 'react'
import styled from 'styled-components'

interface ITagProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
}

const Tag = styled(
  ({ color, onClick, className, children, ...rest }: ITagProps) => (
    <div
      className={`tag ${className}${onClick ? ' button' : ''}`}
      onClick={onClick}
      {...rest}
    >
      <div className="tag__bg" />
      <span className="tag__text">{children}</span>
    </div>
  )
)`
  display: inline-block;
  transition: all 0.3s ease;
  opacity: 0.7;
  padding: 5px 10px;
  color: ${(props) => props.color || '#fff'};
  position: relative;
  .tag__text {
    color: ${(props) => props.color || '#fff'};
    font-size: var(--medium);
  }
  .tag__bg {
    background: ${(props) => props.color || '#fff'};
    opacity: 0.1;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  @media (max-width: 480px) {
    .tag__text {
      font-size: var(--small);
    }
  }
`

const TagGroup = styled.div`
  > *:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`

export { Tag, TagGroup }
