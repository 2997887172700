import React from 'react'
import styled from 'styled-components'

import {
  CheckIcon,
  ExclamationIcon,
  InfoIcon,
  CloseIcon,
  CircleCountdownIcon,
} from '../Icon'

export enum ToastType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  isMounted?: boolean
  type?: ToastType
  title?: string
  description?: string
  onToastClose?: () => void
  stayDuration?: number
  autoDismiss?: boolean
}

interface ToastStates {
  interacted: boolean
}

class Toast extends React.Component<ToastProps, ToastStates> {
  constructor(props: ToastProps) {
    super(props)
    this.state = {
      interacted: false,
    }
  }
  componentDidMount = () => {
    const { stayDuration = 3000, onToastClose } = this.props
    if (onToastClose) {
      setTimeout(this.selfDismiss, stayDuration)
    }
  }

  selfDismiss = () => {
    const { interacted } = this.state
    const { onToastClose } = this.props
    if (!interacted && onToastClose) {
      onToastClose()
    }
  }

  interacted = () => this.setState({ interacted: true })

  render() {
    const { interacted } = this.state
    const {
      isMounted,
      type,
      title,
      description,
      stayDuration,
      onToastClose,
      autoDismiss,
    } = this.props

    return (
      <ToastContainer
        isMounted={isMounted}
        interacted={interacted || !autoDismiss}
        type={type}
        title={title}
        description={description}
        stayDuration={stayDuration}
        onToastClose={onToastClose}
        onClick={this.interacted}
      />
    )
  }
}

interface ToastContainerProps extends ToastProps {
  interacted?: boolean
}

const ToastContainer = styled(
  ({
    isMounted,
    interacted,
    type,
    title,
    description,
    stayDuration = 3000,
    onToastClose,
    className,
    ...rest
  }: ToastContainerProps) => (
    <div
      className={`toast ${className}${
        type === ToastType.success ? ' success' : ''
      }${type === ToastType.info ? ' info' : ''}${
        type === ToastType.warning ? ' warning' : ''
      }${type === ToastType.error ? ' error' : ''}${
        !isMounted ? ' hidden' : ''
      }`}
      {...rest}
    >
      <div className="toast__icon">
        <span className="toast__icon__bg" />
        {type === ToastType.success && <CheckIcon />}
        {(type === ToastType.warning || type === ToastType.error) && (
          <ExclamationIcon />
        )}
        {type === ToastType.info && <InfoIcon />}
      </div>
      <div className="toast__content">
        <span className="toast__title">{title}</span>
        <span className="toast__description">{description}</span>
      </div>
      <div className="toast__close-btn" onClick={onToastClose}>
        <CloseIcon
          className={`toast__close-btn__icon${!isMounted ? ' hidden' : ''}`}
        />
        <CircleCountdownIcon
          hidden={interacted}
          countdown={stayDuration}
          className="toast__close-btn__countdown"
        />
      </div>
      <div className="toast-bg" />
    </div>
  )
)`
  --toast-color: ${({ type }) => {
    let color = 'var(--p-color-1)'
    if (type === ToastType.success) color = '#35D92E'
    if (type === ToastType.warning) color = '#FFC007'
    if (type === ToastType.info) color = '#6EA7FF'
    if (type === ToastType.error) color = 'var(--p-color-red)'
    return color
  }};
  width: 450px;
  max-width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: var(--color-bg-canvas-inset);
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-border-primary);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  animation: toastEntry 0.4s ease-out forwards;
  .toast-bg {
    position: absolute;
    background: var(--toast-color);
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: inherit;
    z-index: -1;
    opacity: 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-radius: 4px 0 0 4px;
    background-color: var(--toast-color);
  }
  .toast__icon {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .toast__icon__bg {
    background: var(--toast-color);
    opacity: 0.35;
    position: absolute;
    z-index: 0;
    width: inherit;
    height: inherit;
    border-radius: 100px;
  }
  .toast__icon svg {
    max-height: 18px;
    max-width: 18px;
  }
  .toast__icon svg path {
    fill: var(--toast-color);
  }
  .toast__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .toast__title,
  .toast__description {
    font-family: D-DIN;
    color: var(--color-text-primary);
  }
  .toast__title {
    font-weight: bold;
    font-size: var(--large);
    opacity: 0.9;
    margin-bottom: 10px;
  }
  &.error .toast__title {
    color: var(--toast-color);
  }
  .toast__description {
    font-size: var(--medium);
    opacity: 0.7;
  }
  .toast__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: visible;
    cursor: pointer;
  }
  .toast__close-btn__icon {
    position: absolute;
    fill: var(--color-text-primary);
    opacity: 0.8;
    transition: all 0.3s ease;
    width: 15px;
    height: 15px;
  }
  .toast__close-btn:hover .toast__close-btn__icon {
    opacity: 1;
    transform: scale(1.05);
  }
  .toast__close-btn__icon.hidden {
    animation: hideClose 0.3s ease-in forwards 3s;
  }
  .toast__close-btn__countdown {
    transition: all 0.3s ease;
    position: absolute;
  }
  &.hidden {
    animation: toastsOut 0.3s ease-in forwards;
  }
  @keyframes toastEntry {
    0% {
      transform: translateX(150%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes toastsOut {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  @keyframes hideClose {
    100% {
      transform: scale(1.4);
      opacity: 1;
    }
  }
  @media (max-width: 470px) {
    padding: 12px;
    .toast__icon {
      margin-right: 12px;
    }
    .toast__title {
      font-size: var(--medium);
      margin-bottom: 5px;
    }
    .toast__description {
      font-size: var(--small);
    }
  }
`

export { Toast }
